import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import cleanDateValue from "../cleanDateValue.js";

export default function LoyaltyProgramActivityRow({
  loyaltyProgramActivity,
  setLoyaltyProgramActivity,
  referenceDate,
  minDate,
  deleteActivity,
}) {
  const { points, tierPoints, loyaltyProgram, source, isCleared, isPending, note, clearDueDate } =
    loyaltyProgramActivity;

  const onSetLoyaltyProgramActivity = (value) => {
    setLoyaltyProgramActivity({ id, ...value }, "SET_LOYALTY_PROGRAM_ACTIVITY");
  };

  const { id } = loyaltyProgram;
  const onDeleteClick = () => {
    deleteActivity(id);
  };

  const onChangeSource = (event) => {
    onSetLoyaltyProgramActivity({
      source: event.target.value,
    });
  };

  const onPendingChange = (event) => {
    onSetLoyaltyProgramActivity({
      isPending: event.target.checked,
    });
  };

  const onClearedChange = (event) => {
    onSetLoyaltyProgramActivity({
      isCleared: event.target.checked,
      isPending: event.target.checked ? false : isPending,
    });
  };

  const onClearDueDateChange = (newValue) => {
    const next = cleanDateValue(newValue);

    onSetLoyaltyProgramActivity({
      clearDueDate: next,
    });
  };

  return (
    <Box key={id}>
      <Stack direction="row" spacing={1}>
        <TextField
          label="Program"
          value={loyaltyProgram.title}
          disabled
          required
          variant="standard"
          margin="none"
          sx={{
            width: 150,
            minWidth: 150,
          }}
        />

        <TextField
          select
          label="Source"
          variant="standard"
          margin="none"
          size="small"
          required
          disabled={loyaltyProgram.sources.length === 0}
          autoComplete="off"
          value={loyaltyProgram.sources.length === 0 ? "" : source}
          onChange={onChangeSource}
          sx={{
            width: 150,
            minWidth: 150,
          }}
          SelectProps={{
            MenuProps: {
              MenuListProps: {
                dense: true,
              },
            },
          }}
        >
          {loyaltyProgram.sources.map((source) => (
            <MenuItem key={source} value={source}>
              {source}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Points"
          value={points}
          onChange={(event) => {
            onSetLoyaltyProgramActivity({ points: event.target.value });
          }}
          inputProps={{
            inputMode: "numeric",
          }}
          variant="standard"
          margin="none"
          sx={{ maxWidth: 80 }}
        />

        <TextField
          label="Tier points"
          value={tierPoints}
          onChange={(event) => {
            onSetLoyaltyProgramActivity({ tierPoints: event.target.value });
          }}
          inputProps={{
            inputMode: "numeric",
          }}
          variant="standard"
          margin="none"
          sx={{ maxWidth: 80 }}
        />

        <DatePicker
          disableToolbar
          variant="inline"
          inputVariant="standard"
          autoOk
          label="Clear Due Date"
          value={clearDueDate || null}
          // maxDate={state.bookingDateMaxValue}
          minDate={minDate}
          onChange={onClearDueDateChange}
          timezone="UTC"
          referenceDate={referenceDate}
          showDaysOutsideCurrentMonth
          format="dd/MM/yy"
          slotProps={{
            openPickerButton: { size: "small" },
            openPickerIcon: { fontSize: "small" },
            textField: {
              variant: "standard",
              label: "Clear Due Date",
              margin: "none",
            },
          }}
        />

        <FormControlLabel
          onChange={onPendingChange}
          control={<Checkbox checked={isPending} size="small" />}
          label="Pending"
        />

        <FormControlLabel
          onChange={onClearedChange}
          control={<Checkbox checked={isCleared} size="small" />}
          label="Cleared"
        />

        <Box sx={{ flexGrow: 1 }} />
        <Divider orientation="vertical" variant="middle" flexItem />

        <Box sx={{ alignSelf: "center" }}>
          <IconButton size="small" color="inherit" onClick={onDeleteClick}>
            <DeleteForeverIcon fontSize="small" />
          </IconButton>
        </Box>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
        <TextField
          label="Note"
          fullWidth
          value={note}
          onChange={(event) => {
            onSetLoyaltyProgramActivity({ note: event.target.value });
          }}
          variant="standard"
          margin="none"
        />
      </Stack>
    </Box>
  );
}
