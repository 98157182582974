import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import now from "../../lib/now.js";
import { db } from "../../models/db.js";
import LoyaltyProgram from "../../models/LoyaltyProgram.js";

export default function CreateLoyaltyProgramCard() {
  const [name, setName] = useState("");
  const [sources, setSources] = useState("");

  const isValid = name.length > 3;
  const onPrimaryClick = async () => {
    const item = new LoyaltyProgram({
      title: name,
      sources: sources
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item.length),
    });
    item.createdAt = now();
    item.updatedAt = item.createdAt;
    await db.loyaltyPrograms.put(item);
    setName("");
  };

  const onNameChange = (event) => setName(event.target.value);
  const onSourcesChange = (event) => setSources(event.target.value);

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <TextField value={name} label="Name" fullWidth margin="none" required onChange={onNameChange} />
          <TextField value={sources} label="Sources" fullWidth margin="none" onChange={onSourcesChange} />
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          disabled={!isValid}
          onClick={onPrimaryClick}
          startIcon={<AddIcon fontSize="small" />}
          color="primary"
          variant="contained"
        >
          Create
        </Button>
      </CardActions>
    </Card>
  );
}
