import React from "react";
import SubScrollLayout from "../SubScrollLayout.js";
import { StayBrandsDetailsCardQuery } from "./StayBrandsDetailsCard.js";
import MainScrollLayout from "../MainScrollLayout.js";
import StayBrandsBookingsList from "./StayBrandsBookingsList.js";
import { useParams } from "react-router-dom";

export default function StayBrandsBookingsListPage() {
  const { stayBrandId } = useParams();
  return (
    <MainScrollLayout fixed flexColumn>
      <StayBrandsDetailsCardQuery stayBrandId={stayBrandId} />
      <SubScrollLayout>
        <StayBrandsBookingsList stayBrandId={stayBrandId} />
      </SubScrollLayout>
    </MainScrollLayout>
  );
}
