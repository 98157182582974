import LoyaltyProgramActivityValidator from "./LoyaltyProgramActivityValidator.js";

/**
 * Represents an activity in a Loyalty Program, such as an earn or redemption.
 */
export default class LoyaltyProgramActivity {
  constructor({
    loyaltyProgram,
    points,
    tierPoints,
    rewardValue,
    isPending,
    isCleared,
    source,
    note,
    clearDueDate,
  } = {}) {
    this.source = source || undefined;
    this.points = points?.length === 0 ? undefined : (points ?? undefined);
    this.loyaltyProgram = loyaltyProgram
      ? { id: loyaltyProgram.id, title: loyaltyProgram.title, sources: loyaltyProgram.sources }
      : undefined;
    this.tierPoints = tierPoints?.length === 0 ? undefined : (tierPoints ?? undefined);
    this.rewardValue = rewardValue?.length === 0 ? undefined : (rewardValue ?? undefined);
    this.isPending = isPending ?? undefined;
    this.isCleared = isCleared ?? undefined;
    this.note = note || undefined;
    this.clearDueDate = clearDueDate || undefined;
  }

  /**
   * The Loyalty Program. A Segment or Stay can have more than one activity, but only one activity per Loyalty Program.
   * @type {{id: String, title: String, sources: String[]}}
   */
  loyaltyProgram;

  /**
   * The Loyalty Program Source
   * @type {String}
   */
  source;

  /**
   * Optional free text.
   * @type {String}
   */
  note;

  /**
   * @type {Number|undefined}
   */
  points;

  /**
   * @type {Number|undefined}
   */
  tierPoints;

  /**
   * @type {Number|undefined}
   */
  rewardValue;

  /**
   * @type {Boolean|undefined}
   */
  isPending;

  /**
   * @type {Boolean|undefined}
   */
  isCleared;

  /**
   * When this award is expected to clear
   * @type {Date|undefined}
   */
  clearDueDate;

  toJSON() {
    const { error, value } = LoyaltyProgramActivityValidator.validate(this);
    if (error === undefined) {
      return value;
    }
    console.log(error, value);
    return undefined;
  }

  /**
   * Indicates if this Agent and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = LoyaltyProgramActivityValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
