import Typography from "@mui/material/Typography";
import React from "react";
import useStayBrands from "../../contexts/StayBrands/useStayBrands.js";
import { AutoCompleteListItem } from "./AutocompleteList.js";
import CreatableAutocomplete from "./CreatableAutocomplete.js";

export default function StayBrandsAutocomplete({ value, onChange, label }) {
  const { stayBrands, isLoaded } = useStayBrands();

  const renderOption = (props, option) => {
    return (
      <AutoCompleteListItem {...props} key={props.key}>
        <Typography variant="body2">{option.title}</Typography>
      </AutoCompleteListItem>
    );
  };

  return (
    <CreatableAutocomplete
      value={value}
      onChange={onChange}
      options={stayBrands}
      label={label || "Brand"}
      clearOnBlur
      sx={{ width: 300 }}
      required
      loading={!isLoaded}
      renderOption={renderOption}
    />
  );
}
