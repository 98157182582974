import Joi from "joi";

const LoyaltyProgramActivityValidator = Joi.object({
  loyaltyProgram: Joi.object({
    title: Joi.string().required(),
    id: Joi.string().required(),
    sources: Joi.array().items(Joi.string().optional()).required(),
  }).required(),
  source: Joi.string().optional(),
  note: Joi.string().optional(),
  points: Joi.number().min(0).optional().precision(0),
  tierPoints: Joi.number().min(0).optional().precision(0),
  rewardValue: Joi.number().min(0).optional().precision(2),
  isPending: Joi.boolean().optional(),
  isCleared: Joi.boolean().optional(),
  clearDueDate: Joi.date().optional(),
}).label("Loyalty Program Activity");

export default LoyaltyProgramActivityValidator;
