import { useLiveQuery } from "dexie-react-hooks";
import { useIntl } from "react-intl";
import React from "react";
import { db } from "../../models/db.js";
import FeatureCard from "../FeatureCard.js";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export function CarrierDetailsCardQuery({ carrierId }) {
  const carrier = useLiveQuery(() => db.carriers.get(carrierId));

  return <CarrierDetailsCard carrier={carrier} />;
}

export default function CarrierDetailsCard({ carrier }) {
  const { formatNumber } = useIntl();

  return (
    <CardContent>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Card elevation={0}>
            <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
              <Typography component="div" variant="h5" gutterBottom>
                {carrier?.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}>
          {/*<FeatureCard title="Total Nights" value={formatNumber(trip.totalNights || 0)} variant="outlined" />*/}
        </Grid>

        <Grid item xs={2}>
          {/*<FeatureCard*/}
          {/*  title="Payments Due"*/}
          {/*  value={formatNumber(trip.totalPricePaidUnpaid, { style: "currency", currency: "GBP" })}*/}
          {/*  variant="outlined"*/}
          {/*/>*/}
        </Grid>

        <Grid item xs={2}>
          <FeatureCard
            title="Total Paid"
            value={formatNumber(carrier?.lifetimeSpend || 0, { style: "currency", currency: "GBP" })}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}
