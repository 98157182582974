import { useLocation, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { db } from "../../models/db.js";
import BookingEditor from "../BookingEditor/BookingEditor.js";

export default function BookingDetailsPage() {
  let { bookingId } = useParams();
  let location = useLocation();
  const source = location.state?.source;

  const [existingData, setExistingData] = useState({ booking: null });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await db.getBooking(bookingId);
        setExistingData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [bookingId]);

  if (!existingData?.booking) {
    return null;
  }
  return <BookingEditor isEditing existingData={existingData} source={source} />;
}
