import { useLiveQuery } from "dexie-react-hooks";
import { useIntl } from "react-intl";
import React from "react";
import { db } from "../../models/db.js";
import FeatureCard from "../FeatureCard.js";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export function StayBrandsDetailsCardQuery({ stayBrandId }) {
  const stayBrand = useLiveQuery(() => db.stayBrands.get(stayBrandId));

  return <StayBrandsDetailsCard stayBrand={stayBrand} />;
}

export default function StayBrandsDetailsCard({ stayBrand }) {
  const { formatNumber } = useIntl();

  const hasCurrency = stayBrand?.totalPrice && stayBrand?.totalPrice.length > 0;
  const hasMultipleCurrency = hasCurrency && stayBrand?.totalPrice.length > 1;
  let displayCurrency = hasCurrency && !hasMultipleCurrency ? stayBrand?.totalPrice?.[0] : null;

  if (displayCurrency?.currency === "GBP") {
    displayCurrency = null;
  }
  return (
    <CardContent>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Card elevation={0}>
            <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
              <Typography component="div" variant="h5" gutterBottom>
                {stayBrand?.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={2}>
          {displayCurrency && (
            <FeatureCard
              title="Total Paid"
              value={formatNumber(displayCurrency.price, {
                style: "currency",
                currency: displayCurrency.currency,
              })}
              variant="outlined"
            />
          )}
        </Grid>

        <Grid item xs={2}>
          <FeatureCard
            title="Total Nights"
            value={stayBrand ? formatNumber(stayBrand.totalNights || 0) : null}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={2}>
          <FeatureCard
            title="Total Cost"
            value={formatNumber(stayBrand?.lifetimeSpend || 0, { style: "currency", currency: "GBP" })}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}
