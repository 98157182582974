import Joi from "joi";

const numbersOnlyRegex = /([^0-9.-])+/gi;
const optionalNumber = Joi.number().min(0).allow("").empty("");
const integerValidatorSchema = optionalNumber.precision(0);

const numberValidatorSchema = optionalNumber.precision(2);

const latitudeValidatorSchema = Joi.number().precision(8).min(-90).max(90);
const longitudeValidatorSchema = Joi.number().precision(8).min(-180).max(180);

export function latitudeValidator(value) {
  const valueAsString = value.replace(numbersOnlyRegex, "");
  return {
    ...latitudeValidatorSchema.validate(valueAsString),
    valueAsString,
  };
}

export function longitudeValidator(value) {
  const valueAsString = value.replace(numbersOnlyRegex, "");
  return {
    ...longitudeValidatorSchema.validate(valueAsString),
    valueAsString,
  };
}

export default function numberValidator(value) {
  const valueAsString = value.replace(numbersOnlyRegex, "");
  return {
    ...numberValidatorSchema.validate(valueAsString),
    valueAsString,
  };
}

export function integerValidator(value) {
  const valueAsString = value.replace(numbersOnlyRegex, "");

  return {
    ...integerValidatorSchema.validate(valueAsString),
    valueAsString,
  };
}
