import { useLiveQuery } from "dexie-react-hooks";
import React, { useReducer, useEffect } from "react";
import { db } from "../../models/db.js";
import GlobeDataReducer from "./GlobeDataReducer.js";
import GlobeDataInitializer from "./GlobeDataInitializer.js";
import GlobeDataContext from "./GlobeDataContext.js";

export default function GlobeDataProvider({ children, flights, animate, sequence }) {
  const stations = useLiveQuery(() => db.stations.toArray());

  const [state, dispatch] = useReducer(
    GlobeDataReducer,
    { flights, stations, animate, sequence },
    GlobeDataInitializer,
  );

  const setState = (value) => dispatch({ type: "SET_STATE", value });

  useEffect(() => {
    dispatch({ type: "SET_FLIGHTS", value: flights });
  }, [flights]);

  useEffect(() => {
    dispatch({ type: "SET_STATIONS", value: stations });
  }, [stations]);

  return <GlobeDataContext.Provider value={state}>{children}</GlobeDataContext.Provider>;
}
