import Divider from "@mui/material/Divider";
import React from "react";
import SubScrollLayout from "../SubScrollLayout.js";
import BookingsTable from "./BookingsTable.js";
import ItemsByDateTable from "./ItemsByDateTable.js";
import ItemsByLoyaltyTable from "./ItemsByLoyaltyTable.js";
import TripDetailsMap from "./TripDetailsMap.js";
// import TripDetailsViewTabs from "./TripDetailsViewTabs.js";

export default function TripDetailsMain({ state, tab }) {
  const { bookings, groupBookingsByLoyaltyProgram, bookingsOrderedByDate, flights } = state;

  const isMap = tab === "map";

  return (
    <>
      {/*<Box>*/}
      {/*  <Divider />*/}
      {/*  <TripDetailsViewTabs tab={tab} tripId={state.trip.id} />*/}
      {/*  <Divider />*/}
      {/*</Box>*/}
      <Divider />
      {isMap && <TripDetailsMap flights={flights} />}
      {!isMap && (
        <SubScrollLayout key={tab}>
          {tab === "bookings" && <BookingsTable bookings={bookings} />}
          {tab === "loyalty" && <ItemsByLoyaltyTable groupBookingsByLoyaltyProgram={groupBookingsByLoyaltyProgram} />}
          {tab === "itinerary" && <ItemsByDateTable bookingsOrderedByDate={bookingsOrderedByDate} />}
        </SubScrollLayout>
      )}
    </>
  );
}
