import Joi from "joi";
import generateIdFromTitle from "../lib/generateIdFromTitle.js";

export const StayLocationValidator = Joi.object({
  title: Joi.string().required(),
  id: Joi.string().required(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  brand: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
  }).required(),
}).label("Stay Location");

/**
 * Represents a Location, such as a specific hotel.
 */
export default class StayLocation {
  constructor({ id, title, createdAt, updatedAt, brand } = {}) {
    this.id = id || `${brand?.id}_${generateIdFromTitle(title)}`;
    this.title = title || undefined;
    this.createdAt = createdAt || undefined;
    this.updatedAt = updatedAt || undefined;
    this.brand = brand || undefined;
  }

  /**
   * The title.
   * @type {String}
   */
  title;

  /**
   * The creation date.
   * @type {Date}
   */
  createdAt;

  /**
   * The update date.
   * @type {Date}
   */
  updatedAt;

  /**
   * The ID
   * @type {String}
   */
  id;

  /**
   * The brand. Clone.
   * @type {{id: String, title: String}|undefined}
   */
  brand;

  /**
   * Indicates if this instance and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = StayLocationValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
