import Joi from "joi";
import generateIdFromTitle from "../lib/generateIdFromTitle.js";

export const AgentValidator = Joi.object({
  title: Joi.string().required(),
  id: Joi.string().required(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  lifetimeSpend: Joi.number().min(0).precision(2).required(),
}).label("Agent");

/**
 * Represents an Agent, which is a company used to make Bookings.
 */
export default class Agent {
  constructor({ id, title, _id, createdAt, updatedAt, lifetimeSpend } = {}) {
    const candidateId = id || _id;
    this.id = generateIdFromTitle(candidateId || title);
    this.title = title || undefined;
    this.createdAt = createdAt || undefined;
    this.updatedAt = updatedAt || undefined;
    this.lifetimeSpend = lifetimeSpend || 0.0;
  }

  /**
   * The title.
   * @type {String}
   */
  title;

  /**
   * The creation date.
   * @type {Date}
   */
  createdAt;

  /**
   * The update date.
   * @type {Date}
   */
  updatedAt;

  /**
   * The ID
   * @type {String}
   */
  id;

  /**
   * Lifetime spend
   * @type {Number}
   */
  lifetimeSpend;

  /**
   * Indicates if this Agent and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = AgentValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
