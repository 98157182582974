import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";

export const ToggleButtonCompact = styled(ToggleButton)(() => ({
  padding: "3px 9px",
}));

export const ToggleButtonIcon = styled("span")(({ theme }) => ({
  marginRight: theme.spacing(),
  display: "inherit",
  marginLeft: "-2px",
}));
