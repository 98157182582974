import Typography from "@mui/material/Typography";
import React from "react";
import { useIntl } from "react-intl";
import { STAY, TRAVEL } from "../../models/BookingConstants.js";
import { isSameDay } from "date-fns";
import ShortRangeText from "./ShortRangeText.js";
import StatusLabel from "./StatusLabel.js";

export function ShortDate({ date }) {
  const { formatDate } = useIntl();
  return formatDate(date, {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "short",
  });
}

function StayBookingDescriptionText({ booking }) {
  const stay = booking.stays?.[0];

  if (!stay) return null;

  return (
    <Typography component="div" variant="body2" color="secondary">
      <StatusLabel booking={booking} />
      {stay.nights > 0 ? `${stay.nights} nights, ` : null}
      <ShortRangeText start={stay.checkInDate} end={stay.checkOutDate} />
    </Typography>
  );
}

function TravelBookingDescriptionText({ booking }) {
  const numberOfSegments = booking.segments?.length || 0;
  if (!numberOfSegments) return null;

  if (numberOfSegments === 1) {
    // just force the display for single segments
    return (
      <Typography component="div" variant="body2" color="secondary">
        <StatusLabel booking={booking} />
        <ShortDate date={booking.segments[0].departureDate} />
      </Typography>
    );
  }

  if (booking.isReturn) {
    return (
      <Typography component="div" variant="body2" color="secondary">
        <StatusLabel booking={booking} />
        <ShortRangeText start={booking.startDate} end={booking.endDate} />
      </Typography>
    );
  }

  // TODO: Determine if getting from segments is better

  if (booking.startDate && booking.endDate && !isSameDay(booking.startDate, booking.endDate)) {
    return (
      <Typography component="div" variant="body2" color="secondary">
        <StatusLabel booking={booking} />
        <ShortRangeText start={booking.startDate} end={booking.endDate} />
      </Typography>
    );
  }

  if (booking.startDate) {
    return (
      <Typography component="div" variant="body2" color="secondary">
        <StatusLabel booking={booking} />
        <ShortDate date={booking.startDate} />
      </Typography>
    );
  }

  // TODO: Add other descriptions here

  return null;
}

export default function BookingDescriptionText({ booking }) {
  if (booking.type.includes(STAY)) {
    return <StayBookingDescriptionText booking={booking} />;
  }
  if (booking.type.includes(TRAVEL)) {
    return <TravelBookingDescriptionText booking={booking} />;
  }
  return null;
}
