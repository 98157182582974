import Carrier from "../../models/Carrier.js";
import Station from "../../models/Station.js";
import Segment from "../../models/Segment.js";

export default function SegmentEditorReducerValidator(newData) {
  const newSegmentInstance = new Segment(newData);

  if (
    newData.destination &&
    newData.destination.id &&
    (!newSegmentInstance.destination || newSegmentInstance.destination.id !== newData.destination.id)
  ) {
    newSegmentInstance.destination = { id: newData.destination.id, title: newData.destination.title };
  } else if (newData.destination && !newData.destination.id) {
    // saving a new station
    const newDestination = new Station(newData.destination);
    newSegmentInstance.setDestinationStation(newDestination);
  }

  if (
    newData.origin &&
    newData.origin.id &&
    (!newSegmentInstance.origin || newSegmentInstance.origin.id !== newData.origin.id)
  ) {
    newSegmentInstance.origin = { id: newData.origin.id, title: newData.origin.title };
  } else if (newData.origin && !newData.origin.id) {
    // saving a new station
    const newOrigin = new Station(newData.origin);
    newSegmentInstance.setOriginStation(newOrigin);
  }

  if (
    newData.carrier &&
    newData.carrier.id &&
    (!newSegmentInstance.carrier || newSegmentInstance.carrier.id !== newData.carrier.id)
  ) {
    newSegmentInstance.carrier = { id: newData.carrier.id, title: newData.carrier.title, type: newData.carrier.type };
  } else if (newData.carrier && !newData.carrier.id) {
    // saving a new carrier
    const newCarrier = new Carrier(newData.carrier);
    newSegmentInstance.carrier = { id: newCarrier.id, title: newCarrier.title, type: newCarrier.type };
  }

  newData.isEmpty = newSegmentInstance.isEmpty;
  newData.isValid = newSegmentInstance.isValid;

  return newData;
}
