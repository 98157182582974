import Checkbox from "@mui/material/Checkbox";
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function EditorRowActionsButton({ value, set, onDelete }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const accountMenuOpen = Boolean(anchorEl);

  const { isSkipped, id, isSecondaryPassenger } = value;
  const isSkippedSafe = isSkipped || false;
  const isSecondaryPassengerSafe = isSecondaryPassenger || false;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onToggleIsSkipped = () => {
    set(id, {
      isSkipped: !isSkippedSafe,
    });
  };

  const onToggleIsSecondaryPassenger = () => {
    set(id, {
      isSecondaryPassenger: !isSecondaryPassengerSafe,
    });
  };

  const onDeleteClick = () => {
    handleClose();
    onDelete();
  };

  return (
    <>
      <IconButton onClick={handleMenu} color="inherit" size="small">
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={accountMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          dense: true,
        }}
      >
        <MenuItem selected={isSkippedSafe} onClick={onToggleIsSkipped}>
          <ListItemIcon>
            <Checkbox
              sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
              checked={isSkippedSafe}
              size="small"
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary="Skipped" />
        </MenuItem>

        <MenuItem selected={isSecondaryPassengerSafe} onClick={onToggleIsSecondaryPassenger}>
          <ListItemIcon>
            <Checkbox
              sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
              checked={isSecondaryPassengerSafe}
              size="small"
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary="Secondary Pax." />
        </MenuItem>

        <Divider />

        <MenuItem onClick={onDeleteClick}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
