export default function TripDetailsMangerInitialState({ flights, stations, animate, sequence }) {
  return {
    flights: flights || undefined,
    stations: stations || undefined,
    isLoaded: false,
    uniqueRoutes: [],
    labels: [],
    animate: animate === true, // should the arc lines be animated
    sequence: sequence === true, // should the flights be unique or in sequence
  };
}
