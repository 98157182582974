import TextField from "@mui/material/TextField";
import React from "react";
import MenuItem from "@mui/material/MenuItem";

export default function BookingStatusField({ value, onChange, disabled }) {
  return (
    <TextField
      select
      label="Status"
      variant="outlined"
      margin="none"
      size="small"
      autoComplete="off"
      fullWidth
      value={value || "UNKNOWN"}
      onChange={onChange}
      disabled={disabled}
      SelectProps={{
        MenuProps: {
          MenuListProps: {
            dense: true,
          },
        },
      }}
    >
      {!value && <MenuItem value="UNKNOWN">Unknown</MenuItem>}
      <MenuItem value="BOOKED">Booked</MenuItem>
      <MenuItem value="REFUNDED">Refunded</MenuItem>
      <MenuItem value="CANCELLED">Cancelled</MenuItem>
    </TextField>
  );
}
