import React, { useState, useEffect } from "react";
import StorageManagerContext from "./StorageManagerContext.js";

export default function StorageManagerProvider({ children }) {
  const hasStorageManager = Boolean(navigator.storage && navigator.storage.persist);
  const [state, setState] = useState({
    isPersistent: undefined,
    isChecked: false,
    hasStorageManager,
  });

  const checkCurrentState = async () => {
    try {
      return navigator.storage.persisted();
    } catch (ex) {
      console.log(ex);
      return undefined;
    }
  };

  const request = async () => {
    let isPersistent;
    try {
      isPersistent = await navigator.storage.persist();
    } catch (ex) {
      console.log(ex);
    }

    setState({
      ...state,
      isChecked: true,
      isPersistent,
    });

    return isPersistent;
  };

  useEffect(() => {
    checkCurrentState().then((isPersistent) => {
      setState({
        ...state,
        isChecked: true,
        isPersistent,
      });
    });
  }, []);

  const value = {
    ...state,
    request,
  };

  return <StorageManagerContext.Provider value={value}>{children}</StorageManagerContext.Provider>;
}
