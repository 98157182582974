import Joi from "joi";

const SegmentValidator = Joi.object({
  id: Joi.string().optional(),
  trip: Joi.object({
    id: Joi.string().optional(),
    title: Joi.string().optional(),
  }).optional(),
  booking: Joi.object({
    id: Joi.string().optional(),
    status: Joi.string().optional(),
    bookingReference: Joi.string().optional().empty(""),
  }).optional(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  departureDate: Joi.date().optional(),
  isLeadingCarrier: Joi.boolean().optional(),
  isSkipped: Joi.boolean().optional(),
  isSecondaryPassenger: Joi.boolean().optional(),
  isOvernight: Joi.boolean().optional(),
  origin: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
  }).required(),
  destination: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
  }).required(),
  eTicket: Joi.string().optional(),
  flightNumber: Joi.string().optional().uppercase(),
  carrierReference: Joi.string().optional(),
  classCode: Joi.string().uppercase().optional(),
  note: Joi.string().optional(),
  carrier: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
    type: Joi.string().required(),
  }).required(),
  loyaltyProgramIds: Joi.array().items(Joi.string()).required(),
  loyaltyProgramActivities: Joi.array().items(Joi.object()).required(),
}).label("Segment");

export default SegmentValidator;
