import { useParams } from "react-router-dom";
import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import TripDetailsManager from "./TripDetailsManager.js";

export default function TripDetailsPage() {
  const { tripId, tab } = useParams();

  const trip = useLiveQuery(() => db.trips.get(tripId), [tripId]);

  const bookings = useLiveQuery(() => db.bookings.where({ "trip.id": tripId }).sortBy("bookingDate"), [tripId]);

  if (trip === undefined || bookings === undefined) {
    return null;
  }

  return <TripDetailsManager trip={trip} bookings={bookings} tab={tab || "bookings"} />;
}
