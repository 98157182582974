import React from "react";
import MainScrollLayout from "../MainScrollLayout.js";
import StationBookingsList from "./StationBookingsList.js";
import { useParams } from "react-router-dom";

export default function StationBookingsListPage() {
  const { stationId } = useParams();
  return (
    <MainScrollLayout>
      <StationBookingsList stationId={stationId} />
    </MainScrollLayout>
  );
}
