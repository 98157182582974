import { BOOKED } from "../../models/BookingConstants.js";
import getSegmentFullTitle from "../../models/getSegmentFullTitle.js";

function convertFlightToRoute({ route, nextState }) {
  route.count = nextState.sequence ? 1 : route.segments.length;
  route.startStation = nextState.stations.find((item) => item.id === (route.start || route.origin.id));
  route.endStation = nextState.stations.find((item) => item.id === (route.end || route.destination.id));

  if (nextState.sequence) {
    // Allow the route to have a title.
    route.routeName = getSegmentFullTitle({ segment: route, showFlightNumber: true });
  }

  route.hasLocation =
    route.startStation &&
    route.endStation &&
    route.startStation.lat !== undefined &&
    route.startStation.lon !== undefined &&
    route.endStation.lat !== undefined &&
    route.endStation.lon !== undefined;

  return route;
}

export default function GlobeDataReducer(state, action) {
  const { type, value } = action;
  if (type === "SET_STATE") {
    return { ...state, ...value };
  }

  let nextState;
  if (type === "SET_FLIGHTS") {
    nextState = { ...state, flights: value };
  } else if (type === "SET_STATIONS") {
    nextState = { ...state, stations: value };
  }

  if (nextState) {
    nextState.isLoaded = nextState.flights !== undefined && nextState.stations !== undefined;

    if (!nextState.isLoaded) {
      return nextState;
    }

    if (nextState.sequence) {
      // Data is loaded, add each specific flight as a route, even if its an overlapping duplicate
      nextState.uniqueRoutes = nextState.flights;

      nextState.uniqueRoutes = [...nextState.uniqueRoutes].map((item) => {
        return convertFlightToRoute({ route: item, nextState });
      });
    } else {
      // Data is loaded, and flatten all the flights into non-duplicate routes
      nextState.uniqueRoutes = nextState.flights.reduce((acc, segment) => {
        // Don't show skipped or cancelled bookings on the map
        if (segment.isSkipped || segment.booking.status !== BOOKED || segment.isSecondaryPassenger) {
          return acc;
        }
        const routeParts = [segment.origin.id, segment.destination.id].sort();
        const routeKey = routeParts.join("-");
        const [start, end] = routeParts;
        if (!acc.has(routeKey)) {
          // add route
          acc.set(routeKey, { segments: [segment], start, end });
        } else {
          // modify
          const existingValue = acc.get(routeKey);
          acc.set(routeKey, { ...existingValue, segments: [existingValue.segments, segment] });
        }
        return acc;
      }, new Map());

      nextState.uniqueRoutes = [...nextState.uniqueRoutes].map((item) => {
        const route = item[1];
        return convertFlightToRoute({ route, nextState });
      });
    }

    nextState.uniqueRoutes = nextState.uniqueRoutes.filter((item) => item.hasLocation);

    if (nextState.sequence) {
      // Label each airport for sequence
      const uniqueAirports = nextState.uniqueRoutes.reduce((acc, segment) => {
        if (!acc.has(segment.origin.id)) {
          acc.set(segment.origin.id, {
            id: segment.origin.id,
            title: segment.origin.title,
            lat: segment.startStation.lat,
            lon: segment.startStation.lon,
          });
        }

        if (!acc.has(segment.destination.id)) {
          acc.set(segment.destination.id, {
            id: segment.destination.id,
            title: segment.destination.title,
            lat: segment.endStation.lat,
            lon: segment.endStation.lon,
          });
        }
        return acc;
      }, new Map());

      nextState.labels = [...uniqueAirports].map(([, item]) => ({
        lat: item.lat,
        lng: item.lon,
        text: item.id,
      }));

      // nextState.labels = [
      //   {
      //     lat: 51.4706, // LHR
      //     lng: -0.461941,
      //     color: "#FFC700",
      //     text: "LHR",
      //   },
      //   {
      //     lat: 40.6398,
      //     lng: -73.7789,
      //     color: "#FFC700",
      //     text: "JFK",
      //   },
      //   {
      //     lat: -23.435556,
      //     lng: -46.473057,
      //     color: "#FFC700",
      //     text: "GRU",
      //   },
      // ];
    } else {
      // Label only the most popular airports in non-sequence
      // TODO: Implement popular airport
    }
  }

  return nextState || state;
}
