import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import { db } from "../../models/db";
import TripsContext from "./TripsContext.js";

export default function TripsProvider({ children }) {
  const trips = useLiveQuery(() => db.trips.toCollection().reverse().sortBy("sortDate"));

  return <TripsContext.Provider value={{ trips: trips || [] }}>{children}</TripsContext.Provider>;
}
