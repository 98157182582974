// import { BSON } from "bson";
import EJSON from "ejson";
import { db } from "./db.js";

export default async function extractData() {
  const result = {};

  const loaders = db.tables.map(async (table) => {
    result[table.name] = await db.table(table.name).toArray();
  });

  await Promise.allSettled(loaders);

  console.log(result);
  return result;
}

export async function importData(data) {
  const loaders = Object.entries(data).map(([key, value]) => {
    return db.table(key).bulkPut(value);
  });
  await Promise.allSettled(loaders);
}

export async function extractAsJSON() {
  const data = await extractData();
  return JSON.stringify(data);
}

// export async function extractAsBSON() {
//   const data = await extractData();
//   return BSON.serialize(data);
// }

export async function extractAsEJSON() {
  const data = await extractData();
  return EJSON.stringify(data);
}

export async function importAsEJSON(ejson) {
  return EJSON.parse(ejson);
}

// export async function importAsBSON(bson) {
//   return BSON.deserialize(bson);
// }
