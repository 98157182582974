const currency = [
  { id: "USD", title: "USD" },
  { id: "BRL", title: "BRL" },
  { id: "EUR", title: "EUR" },
  { id: "GBP", title: "GBP" },
  { id: "CHF", title: "CHF" },
  { id: "ILS", title: "ILS" },
  { id: "NOK", title: "NOK" },
  { id: "DKK", title: "DKK" },
].sort((a, b) => a.title.localeCompare(b.title, "en", { sensitivity: "base" }));

export default currency;
