import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import { Link as ReactRouterLink } from "react-router-dom";
import { TRAVEL, STAY } from "../../models/BookingConstants.js";
import { FLIGHT } from "../../models/CarrierConstants.js";
import getSegmentFullTitle from "../../models/getSegmentFullTitle.js";
import getStayFullTitle from "../../models/getStayFullTitle.js";
import CopyToClipboardText from "../CopyToClipboardText.js";
import LoyaltyProgramActivityNameLink from "../LoyaltyProgramActivityList/LoyaltyProgramActivityNameLink.js";
import BookingTypeIcon from "./BookingTypeIcon.js";
import TableSortLabel from "@mui/material/TableSortLabel";
// import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
// import VerifiedIcon from "@mui/icons-material/Verified";
import LoyaltyProgramActivityStatusChip from "../LoyaltyProgramActivityList/LoyaltyProgramActivityStatusChip.js";

function ItemTitleTableCell({ item, booking, colSpan, loyaltyProgramActivity, type }) {
  if (type === TRAVEL) {
    const parts = [];

    if (item.flightNumber) {
      parts.push(item.flightNumber);
    }

    if (item.classCode) {
      parts.push(`Class: ${item.classCode}`);
    }

    let isCodeShare;
    let isMarketedAndOperated;
    if (booking.leadingCarrier?.type === FLIGHT) {
      if (item.carrier.id !== booking.leadingCarrier.id) {
        parts.push(`Sold by: ${booking.leadingCarrier.title}`);
        isCodeShare = true;
      } else if (item.carrier.id === booking.leadingCarrier.id) {
        isMarketedAndOperated = true;
      }
    }

    const hasNote = loyaltyProgramActivity?.note?.length > 0;
    return (
      <TableCell colSpan={colSpan}>
        <div>
          <Link to={`/bookings/${booking.id}`} component={ReactRouterLink}>
            {getSegmentFullTitle({
              segment: item,
              showStationIds: item.carrier.type === FLIGHT || booking.leadingCarrier?.type === FLIGHT,
            })}
          </Link>
          {parts.length > 0 ? ` - ${parts.join(", ")}` : ""}
        </div>

        {(isMarketedAndOperated || isCodeShare) && (
          <Box sx={{ marginTop: 0.5 }}>
            {/*{isCodeShare && (*/}
            {/*  <Chip*/}
            {/*    icon={<SwapHorizontalCircleIcon fontSize="small" />}*/}
            {/*    label="Codeshare"*/}
            {/*    color="warning"*/}
            {/*    size="small"*/}
            {/*    variant="outlined"*/}
            {/*  />*/}
            {/*)}*/}

            {isCodeShare && (
              <Typography variant="body2" color="warning.main" component="span">
                Codeshare
              </Typography>
            )}

            {/*{isMarketedAndOperated && (*/}
            {/*  <Chip*/}
            {/*    icon={<VerifiedIcon fontSize="small" />}*/}
            {/*    label="Operator"*/}
            {/*    color="success"*/}
            {/*    size="small"*/}
            {/*    variant="outlined"*/}
            {/*  />*/}
            {/*)}*/}

            {isMarketedAndOperated && (
              <Typography variant="body2" color="success.main" component="span">
                Operator
              </Typography>
            )}
          </Box>
        )}

        {hasNote && (
          <Typography variant="body2" gutterBottom sx={{ marginTop: 0.5 }}>
            {loyaltyProgramActivity.note}
          </Typography>
        )}
      </TableCell>
    );
  }

  const parts = [];

  if (item.nights) {
    parts.push(`${item.nights} nights`);
  }
  return (
    <TableCell colSpan={colSpan}>
      <Link to={`/bookings/${booking.id}`} component={ReactRouterLink}>
        {getStayFullTitle(item)}
      </Link>
      {parts.length > 0 ? ` - ${parts.join(", ")}` : ""}
    </TableCell>
  );
}

export default function ItemsByLoyaltyTable({ groupBookingsByLoyaltyProgram }) {
  const { formatDate, formatNumber } = useIntl();

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel active direction="desc" disabled>
                Date
              </TableSortLabel>
            </TableCell>
            <TableCell></TableCell>
            <TableCell colSpan={2}>Description</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell align="right">Points</TableCell>
            <TableCell align="right">Tier Points</TableCell>
            <TableCell>Payment Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupBookingsByLoyaltyProgram.map(({ id, items, loyaltyProgram, points, tierPoints }) => {
            return (
              <Fragment key={id}>
                {items.map(({ loyaltyProgramActivity, item, booking, type }) => {
                  const isTravel = type === TRAVEL;
                  const isStay = type === STAY;

                  let relevantDate = booking.bookingDate;

                  if (isTravel) {
                    relevantDate = item.departureDate;
                  } else if (isStay) {
                    relevantDate = item.checkInDate;
                  }

                  if (!relevantDate) {
                    relevantDate = booking.bookingDate;
                  }

                  return (
                    <TableRow key={item.id} hover>
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {formatDate(relevantDate, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          weekday: "short",
                        })}
                      </TableCell>
                      <TableCell>
                        <BookingTypeIcon booking={booking} type={type} />
                      </TableCell>
                      <ItemTitleTableCell
                        colSpan={item.eTicket?.length ? 1 : 2}
                        item={item}
                        booking={booking}
                        loyaltyProgramActivity={loyaltyProgramActivity}
                        type={type}
                      />
                      {item.eTicket && (
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <CopyToClipboardText value={item.eTicket} />
                        </TableCell>
                      )}

                      <TableCell>
                        {loyaltyProgram ? (
                          <LoyaltyProgramActivityNameLink loyaltyProgramActivity={loyaltyProgramActivity} />
                        ) : (
                          "None"
                        )}
                      </TableCell>
                      <TableCell>
                        <CopyToClipboardText value={item.carrierReference || booking.bookingReference} />
                      </TableCell>
                      <TableCell align="right">
                        {loyaltyProgramActivity?.points !== undefined
                          ? formatNumber(loyaltyProgramActivity.points)
                          : ""}
                      </TableCell>
                      <TableCell align="right">
                        {loyaltyProgramActivity?.tierPoints !== undefined
                          ? formatNumber(loyaltyProgramActivity.tierPoints)
                          : ""}
                      </TableCell>
                      <TableCell>
                        <LoyaltyProgramActivityStatusChip loyaltyProgramActivity={loyaltyProgramActivity} />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {!loyaltyProgram && (
                  <TableRow key={id}>
                    <TableCell colSpan={10} align="right"></TableCell>
                  </TableRow>
                )}
                {loyaltyProgram && (
                  <TableRow key={id}>
                    <TableCell colSpan={6} align="right">{`Total ${loyaltyProgram.title}`}</TableCell>
                    <TableCell align="right">{formatNumber(points) || "-"}</TableCell>
                    <TableCell align="right">{formatNumber(tierPoints) || "-"}</TableCell>
                    <TableCell colSpan={1}></TableCell>
                  </TableRow>
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
