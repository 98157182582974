import Stay from "../../models/Stay.js";

export default function StayEditorReducerValidator(newData) {
  const newStayInstance = new Stay(newData);

  if (newData.brand && !newData.brand.id) {
    newStayInstance.brand.id = "XX";
  }

  if (newData.location && !newData.location.id) {
    newStayInstance.location.id = "XX";
  }

  newData.isEmpty = newStayInstance.isEmpty;
  newData.isValid = newStayInstance.isValid;
  newData.nights = newStayInstance.nights;

  return newData;
}
