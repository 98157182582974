import React from "react";
import MainScrollLayout from "../MainScrollLayout.js";
import LoyaltyProgramsList from "./LoyaltyProgramsList.js";

export default function LoyaltyProgramsListPage() {
  return (
    <MainScrollLayout>
      <LoyaltyProgramsList />
    </MainScrollLayout>
  );
}
