import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

export function CarrierLink({ id, title }) {
  return (
    <Link to={`/carriers/${id}`} component={ReactRouterLink} color="text.primary" underline="hover">
      {title}
    </Link>
  );
}

export default function SupplierTitleLink({ booking, hideAgentLink, isTravel, isStay }) {
  const hasAgent = hideAgentLink !== true && Boolean(booking.agent);

  const agentLink = hasAgent ? (
    <Link to={`/agents/${booking.agent.id}`} component={ReactRouterLink} color="secondary" underline="hover">
      {booking.agent.title}
    </Link>
  ) : null;

  const hasPreference = isTravel || isStay;

  if (hasPreference !== true && booking.type.length > 1) {
    return (
      <>
        <Typography color="primaryText" variant="body2" component="span" fontStyle="italic">
          Multiple
        </Typography>
        {hasAgent && " / "}
        {agentLink}
      </>
    );
  }

  if (booking.leadingCarrier && isStay !== true) {
    return (
      <>
        <CarrierLink id={booking.leadingCarrier.id} title={booking.leadingCarrier.title} />

        {hasAgent && " / "}
        {agentLink}
      </>
    );
  }
  if (booking.leadingStayBrand && isTravel !== true) {
    return (
      <>
        <Link
          to={`/hotels/${booking.leadingStayBrand.id}`}
          component={ReactRouterLink}
          color="text.primary"
          underline="hover"
        >
          {booking.leadingStayBrand.title}
        </Link>
        {hasAgent && " / "}
        {agentLink}
      </>
    );
  }

  // For service items
  return null;
}
