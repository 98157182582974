import { db } from "../../models/db.js";

export default async function saveData(data, existingData) {
  const carrierIds = [
    ...(data.segments || []),
    ...(data.segmentsToDelete || []),
    ...(existingData?.booking?.segments || []),
  ].reduce((acc, segment) => {
    if (segment.carrier) acc.add(segment.carrier.id);
    return acc;
  }, new Set());

  const tables = [
    db.agents,
    db.paymentMethods,
    db.stations,
    db.carriers,
    db.stayBrands,
    db.stayLocations,
    db.bookings,
    db.segments,
    db.flights,
    db.stays,
  ];

  const result = {};

  const typeWrapper = (type) => (id) => ({ type, id });

  result.modifications = await db.transaction("rw", tables, async () => {
    const saves = [
      ...(data.agent ? [db.agents.put(data.agent)] : []),
      ...(data.paymentMethod ? [db.paymentMethods.put(data.paymentMethod)] : []),
      ...(data.stations || []).map((station) => db.stations.put(station)),
      ...(data.carriers || []).map((carrier) => db.carriers.put(carrier)),
      ...(data.stayBrands || []).map((stayBrand) => db.stayBrands.put(stayBrand)),
      ...(data.stayLocations || []).map((stayLocation) => db.stayLocations.put(stayLocation)),
      db.bookings.put(data.booking).then(typeWrapper("bookings")),
      ...(data.segments || []).map((segment) => db.segments.put(segment).then(typeWrapper("segments"))),
      ...(data.flights || []).map((segment) => db.flights.put(segment).then(typeWrapper("flights"))),
      ...(data.segmentsToDelete || []).map((segment) => db.segments.delete(segment)),
      ...(data.segmentsToDelete || []).map((segment) => db.flights.delete(segment)),
      ...(data.stays || []).map((stay) => db.stays.put(stay).then(typeWrapper("stays"))),
      ...(data.staysToDelete || []).map((stay) => db.stays.delete(stay)),
    ];
    return Promise.all(saves);
  });

  result.updatedCarriers = await Promise.all(
    [...carrierIds].map((carrierId) =>
      db.recalculateCarrier(carrierId).then((carrierSpend) => ({ id: carrierId, lifetimeSpend: carrierSpend })),
    ),
  );

  if (data.booking.leadingCarrier) {
    const updatedCarrier = result.updatedCarriers.find((item) => item.id === data.booking.leadingCarrier.id);
    if (updatedCarrier) {
      result.leadingCarrierLifeTimeSpend = updatedCarrier.lifetimeSpend;
    }
  }

  if (data.booking.leadingStayBrand) {
    result.stayBrandLifeTimeSpend = await db.recalculateStayBrand(data.booking.leadingStayBrand.id);
  }

  if (
    existingData?.booking?.leadingStayBrand &&
    (!data.booking.leadingStayBrand || data.booking.leadingStayBrand.id !== existingData.booking.leadingStayBrand.id)
  ) {
    result.previousStayBrandLifeTimeSpend = await db.recalculateStayBrand(existingData.booking.leadingStayBrand.id);
  }

  if (data.booking.agent) {
    result.agentLifeTimeSpend = await db.recalculateAgent(data.booking.agent.id);
  }

  if (data.booking.trip) {
    result.trip = await db.recalculateTrip(data.booking.trip.id);
  }

  if (existingData?.booking?.trip && (!data.booking.trip || data.booking.trip.id !== existingData.booking.trip.id)) {
    // a trip was removed or changed
    result.previousTrip = await db.recalculateTrip(existingData.booking.trip.id);
  }

  console.log(result);

  return result;
}
