import React from "react";
import StayEditorRow from "./StayEditorRow.js";

export default function BookingStays({ state, setStay }) {
  return (
    <>
      {state.stays.map((stay) => (
        <StayEditorRow
          stay={stay}
          key={stay.id}
          setStay={setStay}
          referenceDate={state.suggestedCheckInDate}
          endDateMaxValue={state.endDateMaxValue}
          bookingDateMinValue={state.bookingDateMinValue}
        />
      ))}
    </>
  );
}
