import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useReducer } from "react";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import { db } from "../../models/db.js";
import FixedFooterAppBar from "../FixedFooterAppBar.js";
import MainScrollLayout from "../MainScrollLayout.js";
import SubScrollLayout from "../SubScrollLayout.js";
import AgentsAutocomplete from "./AgentsAutocomplete.js";
import BookingEditorReducer from "./BookingEditorReducer.js";
import BookingEditorInitialState from "./BookingEditorInitialState.js";
import BookingSegments from "./BookingSegments.js";
import BookingStatusField from "./BookingStatusField";
import BookingStays from "./BookingStays.js";
import cleanDateValue from "./cleanDateValue.js";
import CurrencySelectField from "./CurrencySelectField";
import DeleteBookingButton from "./DeleteBookingButton.js";
import PaymentMethodsAutocomplete from "./PaymentMethodsAutocomplete.js";
import saveData from "./saveData.js";
import AddIcon from "@mui/icons-material/Add";
import TripSelectField from "./TripSelectField";
import numberValidator, { integerValidator } from "./validators";
import generateData from "./generateData.js";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";

export default function BookingEditor({
  existingData,
  isEditing: isEditingPage,
  type,
  trip,
  bookings,
  agent,
  carrier,
  source,
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackBar();
  const { formatNumber } = useIntl();

  const [state, dispatch] = useReducer(
    BookingEditorReducer,
    { existingData, isEditing: isEditingPage, type, trip, bookings, agent, carrier, source },
    BookingEditorInitialState,
  );

  const setState = (value) => dispatch({ type: "SET_STATE", value });

  const setSegment = (id, value, type) => dispatch({ type: type || "SET_SEGMENT", id, value });

  const setStay = (id, value, type) => dispatch({ type: type || "SET_STAY", id, value });

  const onStatusChange = (event) => setState({ status: event.target.value });

  const onNoteChange = (event) => setState({ note: event.target.value });

  const onAgentChange = (agent) => setState({ agent });

  const onPaymentMethodChange = (paymentMethod) => setState({ paymentMethod });

  const onTripChange = (trip) => {
    dispatch({ type: "SET_TRIP", value: trip });
  };

  const onCurrencyChange = (event) => {
    setState({ priceCurrency: event.target.value });
  };

  const onPaidChange = (event) => {
    setState({ isPaid: event.target.checked });
  };

  const onPaidEstimatedChange = (event) => {
    setState({ isPriceEstimated: event.target.checked });
  };

  const editDate = (newValue) => {
    const next = cleanDateValue(newValue);
    setState({ bookingDate: cleanDateValue(next), suggestedCheckInDate: next, suggestedDepartureDate: next });
  };

  const { bookingDate, bookingReference, priceAsString, pricePaidAsString, isValid, isEditing, createdAt } = state;

  const onSaveClick = async () => {
    const data = generateData(state);
    if (!data) return;

    let title = "Booking Created";

    if (existingData?.booking) {
      title = "Booking Updated";
    }
    const saves = await saveData(data, existingData);

    if (saves.stayBrandLifeTimeSpend > 0) {
      enqueueSnackbar({
        message: `${data.booking.leadingStayBrand.title} lifetime spend is ${formatNumber(saves.stayBrandLifeTimeSpend, { style: "currency", currency: "GBP" })}`,
        title,
        variant: "success",
      });
    } else if (saves.leadingCarrierLifeTimeSpend > 0) {
      enqueueSnackbar({
        message: `${data.booking.leadingCarrier.title} lifetime spend is ${formatNumber(saves.leadingCarrierLifeTimeSpend, { style: "currency", currency: "GBP" })}`,
        title,
        variant: "success",
      });
    } else {
      enqueueSnackbar({ message: title, variant: "success" });
    }

    if (source === "flights") {
      navigate(`/flights`);
      return;
    }
    if (data.booking.trip) {
      navigate(`/trips/${data.booking.trip.id}`);
    } else if (source === "agent" && data.booking.agent) {
      navigate(`/agents/${data.booking.agent.id}`);
    } else if (source === "carrier" && data.booking.leadingCarrier) {
      navigate(`/carriers/${data.booking.leadingCarrier.id}`);
    } else {
      navigate(`/bookings/${data.booking.id}`);
    }
  };

  const onAddTravelClick = () => {
    dispatch({ type: "ADD_SEGMENT" });
  };

  const onAddStayClick = () => {
    dispatch({ type: "ADD_STAY" });
  };

  const onDeleteClick = async () => {
    await db.deleteBooking(state.id);
    navigate(state.cancelPath);
    enqueueSnackbar({ title: "Booking Deleted", message: existingData.booking.title, variant: "success" });
  };

  const onViewStateClick = async () => {
    console.log(state);
    generateData(state);
  };

  return (
    <>
      <MainScrollLayout footerBar fixed flexColumn>
        <Card square sx={{ flexShrink: 0 }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      autoOk
                      label="Booking Date"
                      value={bookingDate}
                      maxDate={state.bookingDateMaxValue}
                      minDate={state.bookingDateMinValue}
                      onChange={editDate}
                      timezone="UTC"
                      referenceDate={createdAt}
                      showDaysOutsideCurrentMonth
                      format="dd/MM/yy"
                      slotProps={{
                        openPickerButton: { size: "small" },
                        openPickerIcon: { fontSize: "small" },
                        textField: {
                          variant: "outlined",
                          label: "Booking Date",
                          margin: "none",
                          fullWidth: true,
                          required: true,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      label="Booking Reference"
                      value={bookingReference}
                      onChange={(event) => {
                        setState({ bookingReference: event.target.value });
                      }}
                      fullWidth
                      margin="none"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <BookingStatusField onChange={onStatusChange} value={state.status} />
                  </Grid>

                  <Grid item xs={3}>
                    <AgentsAutocomplete
                      onChange={onAgentChange}
                      value={state.agent}
                      disabled={state.canChangeAgent === false}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextField
                      label="Points Paid"
                      value={state.pointsPaidAsString}
                      error={!state.pointsPaidIsValid}
                      margin="none"
                      onChange={(event) => {
                        const result = integerValidator(event.target.value);

                        setState({
                          pointsPaidAsString: result.valueAsString,
                          pointsPaid: result.value,
                          pointsPaidIsValid: result.error === undefined,
                        });
                      }}
                      inputProps={{
                        inputMode: "numeric",
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Stack spacing={1} direction="row">
                      <TextField
                        label="Price Paid (GBP)"
                        value={pricePaidAsString}
                        margin="none"
                        disabled={state.priceCurrency === "GBP"}
                        error={!state.pricePaidIsValid}
                        onChange={(event) => {
                          const result = numberValidator(event.target.value);

                          setState({
                            pricePaidAsString: result.valueAsString,
                            pricePaid: result.value,
                            pricePaidIsValid: result.error === undefined,
                          });
                        }}
                        inputProps={{
                          inputMode: "numeric",
                        }}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <FormControlLabel
                                sx={{ marginRight: 0 }}
                                disabled={state.priceCurrency === "GBP"}
                                onChange={onPaidEstimatedChange}
                                control={<Checkbox sx={{ padding: 1 }} checked={state.isPriceEstimated} size="small" />}
                                label="Estimated"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <PaymentMethodsAutocomplete onChange={onPaymentMethodChange} value={state.paymentMethod} />
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack spacing={1} direction="row">
                      <TextField
                        label="Price"
                        value={priceAsString}
                        margin="none"
                        error={!state.priceIsValid}
                        onChange={(event) => {
                          const result = numberValidator(event.target.value);

                          let update = {
                            priceAsString: result.valueAsString,
                            price: result.value,
                            priceIsValid: result.error === undefined,
                          };

                          if (state.priceCurrency === "GBP") {
                            setState({
                              ...update,
                              pricePaidAsString: update.priceAsString,
                              pricePaid: update.price,
                              pricePaidIsValid: update.priceIsValid,
                            });
                            return;
                          }
                          setState(update);
                        }}
                        inputProps={{
                          inputMode: "numeric",
                        }}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <FormControlLabel
                                sx={{ marginRight: 0 }}
                                onChange={onPaidChange}
                                control={
                                  <Checkbox sx={{ padding: 1 }} color="success" checked={state.isPaid} size="small" />
                                }
                                label="Paid"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <CurrencySelectField value={state.priceCurrency} onChange={onCurrencyChange} />
                    </Stack>
                  </Grid>

                  <Grid item xs={3}>
                    <TripSelectField
                      value={state.trip}
                      onChange={onTripChange}
                      required={state.tripRequired}
                      disabled={state.disableTripSelection}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Note"
                      value={state.note}
                      margin="none"
                      onChange={onNoteChange}
                      fullWidth
                      required={state.type === "SERVICE"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
        <SubScrollLayout>
          <BookingSegments state={state} setSegment={setSegment} />
          <BookingStays state={state} setStay={setStay} />
        </SubScrollLayout>
      </MainScrollLayout>
      <FixedFooterAppBar>
        <Button
          disabled={!isValid}
          startIcon={<SaveIcon fontSize="small" />}
          color="primary"
          variant="contained"
          onClick={onSaveClick}
        >
          {isEditing ? "Update" : "Save"}
        </Button>

        {state.isEditing && (
          <Button
            startIcon={<CloseIcon fontSize="small" />}
            color="secondary"
            variant="outlined"
            component={ReactRouterLink}
            to={state.cancelPath}
          >
            Close
          </Button>
        )}

        {state.isEditing === false && (
          <Button
            startIcon={<CloseIcon fontSize="small" />}
            color="secondary"
            variant="outlined"
            component={ReactRouterLink}
            to={state.cancelPath}
          >
            Cancel
          </Button>
        )}

        {state.tripPath && state.tripPath !== state.cancelPath && (
          <Button
            startIcon={<ModeOfTravelIcon fontSize="small" />}
            color="secondary"
            variant="outlined"
            component={ReactRouterLink}
            to={state.tripPath}
            disabled={!state.tripPath?.length}
          >
            View Trip
          </Button>
        )}

        <Divider orientation="vertical" variant="middle" flexItem />

        {state.canAddSegments && (
          <Button
            disabled={!state.canAddSegments}
            startIcon={<AddIcon fontSize="small" />}
            color="secondary"
            variant="outlined"
            onClick={onAddTravelClick}
          >
            Add Segment
          </Button>
        )}

        {state.canAddStays && (
          <Button
            startIcon={<AddIcon fontSize="small" />}
            color="secondary"
            variant="outlined"
            onClick={onAddStayClick}
            disabled={state.stays.length !== 0}
          >
            Add Stay
          </Button>
        )}

        <Divider orientation="vertical" variant="middle" flexItem />

        <DeleteBookingButton onClick={onDeleteClick} disabled={!state.isEditing} />

        <Button variant="outlined" color="secondary" onClick={onViewStateClick}>
          View State
        </Button>
      </FixedFooterAppBar>
    </>
  );
}
