import SwitzerRegular from "../../css/Switzer-Regular.woff2";
import SwitzerMedium from "../../css/Switzer-Medium.woff2";
import SwitzerBold from "../../css/Switzer-Bold.woff2";

const cssBaseline = `
        :root {
          color-scheme: dark;
        }
        @font-face {
          font-family: 'Switzer';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${SwitzerRegular}) format('woff2');
        }
        @font-face {
          font-family: 'Switzer';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: url(${SwitzerMedium}) format('woff2');
        }
        @font-face {
          font-family: 'Switzer';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: url(${SwitzerBold}) format('woff2');
        }
        body {
          margin: 0;
          padding: 0 0 150px 0;
          text-size-adjust: 100%;
          font-size: 1rem;
          overflow: hidden;
        }
        html,body {
           overscroll-behavior-y: none;
        },
        html {
           -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        body,
        button,
        input,
        textarea,
        select {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        @media print {
          html { font-size: 14px; }
        }`;

export default cssBaseline;
