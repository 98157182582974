import { FLIGHT } from "../models/CarrierConstants.js";
import { db } from "../models/db.js";

export default async function migrateSegmentsToFlights() {
  const segments = [];
  const stays = [];
  let updatedBookings = null;

  const result = await db.transaction("rw", [db.bookings, db.segments, db.flights], async () => {
    updatedBookings = await db.bookings.toCollection().modify((booking) => {
      if (booking.segments) {
        booking.segments = booking.segments.map((segment) => {
          segment.trip = booking.trip;
          segment.booking = { id: booking.id, status: booking.status, bookingReference: booking.bookingReference };
          delete segment.tripId;
          delete segment.bookingId;
          segments.push(segment);
          return segment;
        });
      }

      if (booking.stays) {
        booking.stays = booking.stays.map((stay) => {
          stay.trip = booking.trip;
          stay.booking = { id: booking.id, status: booking.status, bookingReference: booking.bookingReference };
          delete stay.tripId;
          delete stay.bookingId;
          stays.push(stay);
          return stay;
        });
      }
    });
  });

  console.log(`${updatedBookings} bookings updated`);

  await db.transaction("rw", [db.segments, db.flights], async () => {
    for (let i = 0; i < segments.length; i++) {
      const updatedSegment = segments[i];
      await db.segments.put(updatedSegment);

      if (updatedSegment.carrier.type === FLIGHT) {
        await db.flights.put(updatedSegment);
      }
      console.log(`Updated Segment: ${updatedSegment.id}`);
    }
  });

  await db.transaction("rw", [db.stays], async () => {
    for (let i = 0; i < stays.length; i++) {
      const updatedStay = stays[i];
      await db.stays.put(updatedStay);

      console.log(`Updated Stay: ${updatedStay.id}`);
    }
  });

  if (result) console.log(result);

  console.log(segments);
  console.log(stays);
}
