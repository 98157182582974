import { useIntl } from "react-intl";

const reduce = (acc, value) => {
  acc.set(value.type, value.value);
  return acc;
};

export default function ShortRangeText({ start, end, dense }) {
  const { formatDateTimeRange, formatDateToParts } = useIntl();

  if (!start || !end) {
    return null;
  }

  const formatOptions = {
    year: "numeric",
    month: dense ? "short" : "long",
    day: "numeric",
  };

  if (!dense) {
    formatOptions.weekday = "short";
  }

  /**
   *
   * @param {Date} date
   * @return {Map<String, String>}
   */
  const getParts = (date) => formatDateToParts(date, formatOptions).reduce(reduce, new Map());

  const startParts = getParts(start);
  const endParts = getParts(end);

  if (startParts.get("year") === endParts.get("year") && startParts.get("month") === endParts.get("month")) {
    if (startParts.get("day") === endParts.get("day")) {
      return `${dense ? "" : `${endParts.get("weekday")} `}${endParts.get("day")} ${endParts.get("month")} ${endParts.get("year")}`;
    }
    return `${dense ? "" : `${startParts.get("weekday")} `}${startParts.get("day")}${dense ? "-" : " - "}${dense ? "" : `${endParts.get("weekday")} `}${endParts.get("day")} ${endParts.get("month")} ${endParts.get("year")}`;
  }

  return formatDateTimeRange(start, end, formatOptions);
}
