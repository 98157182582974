export default function emptyStayObject({ bookingId }) {
  const now = new Date();

  return {
    id: self.crypto.randomUUID(),
    bookingId,
    createdAt: now,
    updatedAt: now,
    checkInDate: null,
    checkOutDate: null,
    brand: null,
    location: null,
    isSkipped: undefined,
    roomNumber: "",
    roomName: "",
    note: "",
    bookingReference: "",
    loyaltyProgramIds: [],
    loyaltyProgramActivities: [],
  };
}
