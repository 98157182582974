import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import { db } from "../../models/db.js";
import MainScrollLayout from "../MainScrollLayout.js";
import GlobalMapLoader from "./GlobalMapLoader.js";
import GlobeDataProvider from "./GlobeDataProvider.js";

export default function GlobePage() {
  const flights = useLiveQuery(() => db.flights.toArray());

  return (
    <GlobeDataProvider flights={flights}>
      <MainScrollLayout fixed>
        <GlobalMapLoader />
      </MainScrollLayout>
    </GlobeDataProvider>
  );
}
