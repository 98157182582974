import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import React from "react";
import StayBrandsTableRow from "./StayBrandsTableRow.js";

export default function StayBrandsTable({ stayBrands }) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "20%" }}>ID</TableCell>
            <TableCell sx={{ width: "20%" }}>Name</TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              Total Nights
            </TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              Lifetime Spend
            </TableCell>
            <TableCell sx={{ width: "20%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stayBrands.map((stayBrand) => (
            <StayBrandsTableRow key={stayBrand.id} stayBrand={stayBrand} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
