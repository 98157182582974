import Box from "@mui/material/Box";
import React from "react";

export default function TextWrapper({ children, width = "150px" }) {
  return (
    <Box
      component="span"
      sx={{
        maxWidth: width,
        overflow: "hidden",
        display: "inline-block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </Box>
  );
}
