import React from "react";
import useAgents from "../../contexts/Agents/useAgents.js";
import CreatableAutocomplete from "./CreatableAutocomplete.js";

export default function AgentsAutocomplete({ value, onChange, label, disabled }) {
  const { agents } = useAgents();

  return (
    <CreatableAutocomplete
      value={value}
      onChange={onChange}
      options={agents}
      label={label || "Agent"}
      variant="outlined"
      clearOnBlur
      disabled={disabled}
    />
  );
}
