export default function emptyTravelObject({
  bookingId,
  isLeadingCarrier,
  carrier,
  origin,
  departureDate,
  originTitle,
}) {
  const now = new Date();

  return {
    origin: origin || null,
    destination: null,
    originTitle: originTitle || "",
    destinationTitle: "",
    carrier: carrier || null,
    eTicket: "",
    flightNumber: "",
    id: self.crypto.randomUUID(),
    departureDate: departureDate || null,
    carrierReference: "",
    isEmpty: true,
    isValid: true,
    isSkipped: undefined,
    isSecondaryPassenger: undefined,
    bookingId,
    updatedAt: now,
    createdAt: now,
    isLeadingCarrier: isLeadingCarrier || false,
    loyaltyProgramIds: [],
    loyaltyProgramActivities: [],
  };
}
