import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import useLoyaltyPrograms from "../../../contexts/LoyaltyPrograms/useLoyaltyPrograms.js";
import LoyaltyProgramsAutocomplete from "./LoyaltyProgramsAutocomplete.js";

export default function AddLoyaltyProgramModal({ title, isOpen, onClose, set }) {
  const { loyaltyPrograms } = useLoyaltyPrograms();
  const defaultLoyaltyProgram = (loyaltyPrograms || [])[0] || null;

  const [selectedLoyaltyProgram, setSelectedLoyaltyProgram] = useState(defaultLoyaltyProgram);

  const onCloseClick = () => {
    onClose();
  };

  const onLoyaltyProgramSelected = (newValue) => {
    setSelectedLoyaltyProgram(newValue);
  };

  const onPrimaryClick = () => {
    set(selectedLoyaltyProgram, "ADD_LOYALTY_PROGRAM_ACTIVITY");
    onClose(selectedLoyaltyProgram);
  };

  const isValid = Boolean(selectedLoyaltyProgram);
  return (
    <Dialog open={isOpen} onClose={onCloseClick}>
      <DialogTitle>{title || "Add Loyalty Program"}</DialogTitle>
      <DialogContent>
        <LoyaltyProgramsAutocomplete value={selectedLoyaltyProgram} onChange={onLoyaltyProgramSelected} required />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: "flex-start" }}>
        <Button
          onClick={onPrimaryClick}
          startIcon={<AddIcon fontSize="small" />}
          disabled={!isValid}
          color="primary"
          variant="contained"
        >
          Add
        </Button>
        <Button onClick={onCloseClick} color="secondary" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
