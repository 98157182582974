import Joi from "joi";
import generateIdFromTitle from "../lib/generateIdFromTitle.js";

export const StayBrandValidator = Joi.object({
  title: Joi.string().required(),
  id: Joi.string().required(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  lifetimeSpend: Joi.number().min(0).precision(2).required(),
  totalNights: Joi.number().min(0).required(),
  totalPrice: Joi.array().items(
    Joi.object({
      currency: Joi.string().required(),
      price: Joi.number().min(0).required().precision(2),
    }),
  ),
}).label("Stay Brand");

/**
 * Represents a Brand, which is a hotel chain.
 */
export default class StayBrand {
  constructor({ id, title, createdAt, updatedAt, lifetimeSpend, totalNights, totalPrice } = {}) {
    this.id = id || generateIdFromTitle(title);
    this.title = title || undefined;
    this.createdAt = createdAt || undefined;
    this.updatedAt = updatedAt || undefined;
    this.lifetimeSpend = lifetimeSpend || 0.0;
    this.totalNights = totalNights || 0;
    this.totalPrice = totalPrice || [];
  }

  /**
   * The title.
   * @type {String}
   */
  title;

  /**
   * The creation date.
   * @type {Date}
   */
  createdAt;

  /**
   * The update date.
   * @type {Date}
   */
  updatedAt;

  /**
   * The ID
   * @type {String}
   */
  id;

  /**
   * Lifetime spend
   * @type {Number}
   */
  lifetimeSpend;

  /**
   * The total price in various supplier currencies in the trip. Calculated.
   * @type {{ price: Number, currency: String }[]}
   */
  totalPrice;

  /**
   * Total nights on Stays.
   * @type {Number}
   */
  totalNights;

  /**
   * Indicates if this instance and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = StayBrandValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
