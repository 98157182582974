import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { TRAVEL } from "../../models/BookingConstants.js";
import formatBookingsToMarkdown from "./formatBookingsToMarkdown.js";

export default function AIInterface() {
  const bookings = useLiveQuery(() => db.bookings.where("type").equals(TRAVEL).reverse().sortBy("bookingDate"));

  if (!bookings) return null;

  const bookingsMarkdown = formatBookingsToMarkdown(bookings);

  return (
    <Card square>
      <code>
        <Typography component="pre" variant="body2" sx={{ fontFamily: "monospace" }}>
          {bookingsMarkdown}
        </Typography>
      </code>
    </Card>
  );
}
