import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

export default function TripLink({ trip, id }) {
  if (trip) {
    return (
      <Link to={`/trips/${trip.id}`} component={ReactRouterLink} color="secondary" underline="hover">
        {trip.title}
      </Link>
    );
  }

  if (id) {
    return (
      <Link to={`/trips/${id}`} component={ReactRouterLink} color="secondary" underline="hover">
        {id}
      </Link>
    );
  }
  return (
    <Typography variant="body2" color="warning.main" component="span" fontStyle="italic">
      Unknown
    </Typography>
  );
}
