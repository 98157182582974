import Link from "@mui/material/Link";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

export default function LoyaltyProgramActivityNameLink({ loyaltyProgramActivity }) {
  const { loyaltyProgram, source } = loyaltyProgramActivity;

  const link = (
    <Link to={`/loyalty/${loyaltyProgram?.id}`} component={ReactRouterLink} color="inherit" underline="hover">
      {loyaltyProgram?.title}
    </Link>
  );

  if (source && source !== loyaltyProgram.title) {
    return (
      <>
        {link}
        {` / ${source}`}
      </>
    );
  }

  return link;
}
