import activeFlightsOnly from "./activeFlightsOnly.js";
import bookingsOrderedByDate from "./bookingsOrderedByDate.js";
import groupBookingsByLoyaltyProgram from "./groupBookingsByLoyaltyProgram.js";

export default function TripDetailsManagerReducer(state, action) {
  const { type, value } = action;
  if (type === "SET_STATE") {
    return { ...state, ...value };
  }

  if (type === "SET_BOOKINGS") {
    return {
      ...state,
      bookings: value,
      flights: activeFlightsOnly(value),
      groupBookingsByLoyaltyProgram: groupBookingsByLoyaltyProgram({ bookings: value }),
      bookingsOrderedByDate: bookingsOrderedByDate({ bookings: value }),
    };
  }
  return state;
}
