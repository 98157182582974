import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";
import { Link as ReactRouterLink } from "react-router-dom";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import stripWhiteSpace from "../../lib/stripWhiteSpace.js";
import { db } from "../../models/db.js";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function AgentsTableRow({ agent }) {
  const { enqueueSnackbar } = useSnackBar();
  const { formatNumber } = useIntl();

  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(agent.title);
  const [id, setId] = useState(agent.id);

  const onEditClick = () => {
    setEditMode(!editMode);
  };

  const onDeleteClick = async () => {
    const result = await db.deleteAgent(agent.id);
    if (result?.success) {
      enqueueSnackbar({ message: `${title} Deleted`, variant: "success" });
    } else {
      enqueueSnackbar({ message: result.message, variant: "error" });
    }
  };

  const onCancelClick = () => {
    setEditMode(false);
    setTitle(agent.title);
    setId(agent.id);
  };

  const onSaveClick = async () => {
    await db.updateAgent(
      {
        ...agent,
        id,
        title,
      },
      agent,
    );
    enqueueSnackbar({ message: `${title} Updated`, variant: "success" });
    setEditMode(!editMode);
  };

  const onTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const onIdChange = (event) => {
    setId(stripWhiteSpace(event.target.value.toUpperCase()));
  };

  const onRecalculateLifetimeSpendClick = async () => {
    await db.recalculateAgent(agent.id);
    enqueueSnackbar({ message: `${title} Spend Updated`, variant: "success" });
  };

  return (
    <TableRow hover>
      {!editMode && <TableCell>{agent.id}</TableCell>}
      {editMode && (
        <TableCell>
          <TextField value={id} fullWidth margin="none" required onChange={onIdChange} />
        </TableCell>
      )}
      {!editMode && (
        <TableCell>
          <Link to={`/agents/${agent.id}`} component={ReactRouterLink}>
            {agent.title}
          </Link>
        </TableCell>
      )}
      {editMode && (
        <TableCell>
          <TextField value={title} fullWidth margin="none" required onChange={onTitleChange} />
        </TableCell>
      )}

      <TableCell align="right">
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
          {agent.lifetimeSpend !== undefined ? (
            <span>{formatNumber(agent.lifetimeSpend, { style: "currency", currency: "GBP" })}</span>
          ) : (
            <span>Unknown</span>
          )}

          <IconButton size="small" disabled={editMode} color="inherit" onClick={onRecalculateLifetimeSpendClick}>
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Stack>
      </TableCell>

      <TableCell align="right">
        {editMode && (
          <Button color="secondary" onClick={onCancelClick}>
            Cancel
          </Button>
        )}
        {!editMode && (
          <Button color="secondary" disabled onClick={onDeleteClick}>
            Delete
          </Button>
        )}
        {!editMode && (
          <Button color="secondary" onClick={onEditClick}>
            Edit
          </Button>
        )}
        {editMode && (
          <Button color="primary" onClick={onSaveClick}>
            Save
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}
