import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import React from "react";
import { BOOKED } from "../../models/BookingConstants.js";
import GenericSegmentRow from "./GenericSegmentRow.js";

const startState = () => ({ count: 0 });

export default function GenericSegmentsTable({ segments, linkState, isLoading }) {
  const stats = segments
    ? segments.reduce((acc, current) => {
        if (current.booking.status !== BOOKED || current.isSkipped || current.isSecondaryPassenger) {
          return acc;
        }

        acc.count += 1;

        return acc;
      }, startState())
    : startState();

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel active direction="desc" disabled>
                Departure
              </TableSortLabel>
            </TableCell>
            <TableCell>Airline</TableCell>
            <TableCell>{isLoading ? "Route" : `Route (${stats.count} flights flown)`}</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell>Flight No.</TableCell>
            <TableCell>eTicket</TableCell>
            <TableCell align="right">Class</TableCell>
            <TableCell>Trip</TableCell>
            <TableCell colSpan={3}>Loyalty</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading === false && segments && segments.length === 0 && (
            <TableRow>
              <TableCell colSpan={11}>No results</TableCell>
            </TableRow>
          )}
          {segments?.map((segment) => {
            return <GenericSegmentRow key={segment.id} segment={segment} linkState={linkState} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
