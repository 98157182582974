import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import {
  DialogContentText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import now from "../../lib/now.js";
import { db } from "../../models/db.js";
import Trip from "../../models/Trip.js";

export default function NewTripModal({ isOpen, onClose }) {
  const navigate = useNavigate();

  const [name, setName] = useState("");

  const clearForm = () => {
    setName("");
  };

  const isValid = name.length > 3;

  const saveData = async () => {
    const trip = new Trip();
    trip.createdAt = now();
    trip.updatedAt = trip.createdAt;
    trip.title = name.trim();
    trip.id = self.crypto.randomUUID();
    trip.sortDate = trip.createdAt;
    await db.trips.add(trip);
    return trip;
  };

  const onPrimaryClick = async () => {
    if (!isValid) return;
    const trip = await saveData();
    onClose();
    navigate(`/trips/${trip.id}`);
  };

  const onCloseClick = () => {
    onClose();
    clearForm();
  };

  const onKeyUp = (event) => {
    if (event.key !== "Enter") return;
    onPrimaryClick();
  };

  return (
    <Dialog open={isOpen} onClose={onCloseClick}>
      <DialogTitle>New Trip</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the name of your new trip.</DialogContentText>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Stack direction="row" spacing={2}>
          <TextField
            label="Name"
            fullWidth
            required
            onKeyUp={onKeyUp}
            value={name}
            autoFocus
            autoComplete="off"
            onChange={(event) => setName(event.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          onClick={onPrimaryClick}
          startIcon={<AddIcon fontSize="small" />}
          color="primary"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
