import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import getStayFullTitle from "../../models/getStayFullTitle.js";
import EditorRowActionsButton from "./EditorRowActionsButton.js";
import LoyaltyProgramActivityButton from "./LoyaltyProgramActivity/LoyaltyProgramActivityButton.js";
import StayBrandsAutocomplete from "./StayBrandsAutocomplete.js";
import cleanDateValue from "./cleanDateValue.js";
import { addDays } from "date-fns";
import StayLocationsAutocomplete from "./StayLocationsAutocomplete.js";

export default function StayEditorRow({ stay, setStay, referenceDate, endDateMaxValue, bookingDateMinValue }) {
  const { id, bookingReference, checkInDate, checkOutDate, nights, roomName, roomNumber, createdAt, brand, location } =
    stay;

  const onStayBrandSelected = (newValue) => {
    setStay(id, {
      brand: newValue,
      location: null,
    });
  };

  const onStayLocationSelected = (newValue) => {
    if (!newValue) {
      setStay(id, {
        location: null,
      });
      return;
    }
    if (newValue.brand?.id) {
      // Force the selection of the parent brand.
      setStay(id, {
        location: newValue,
        brand: { id: newValue.brand.id, title: newValue.brand.title },
      });
      return;
    }
    setStay(id, {
      location: newValue,
    });
  };

  const onDeleteClick = () => {
    setStay(id, undefined, "DELETE_STAY");
  };

  const onLoyaltyProgramActivityChange = (value, event) => {
    setStay(id, value, event);
  };

  return (
    <Card key={id} elevation={1}>
      <CardContent>
        <Stack direction="row" spacing={1}>
          <StayBrandsAutocomplete value={brand} onChange={onStayBrandSelected} />
          <StayLocationsAutocomplete
            value={location}
            onChange={onStayLocationSelected}
            stayBrandId={brand?.id || null}
            isNewBrandSelected={Boolean(brand?.title && !brand?.id)}
          />
          <DatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            autoOk
            label="Check-in"
            maxDate={endDateMaxValue}
            minDate={bookingDateMinValue}
            value={checkInDate || null}
            onChange={(newValue) => {
              const nextValue = { checkInDate: cleanDateValue(newValue) };
              if (!checkOutDate) {
                nextValue.checkOutDate = addDays(nextValue.checkInDate, 1);
              }
              setStay(id, nextValue);
            }}
            timezone="UTC"
            showDaysOutsideCurrentMonth
            format="dd/MM/yy"
            referenceDate={referenceDate || createdAt}
            slotProps={{
              openPickerButton: { size: "small" },
              openPickerIcon: { fontSize: "small" },
              textField: {
                variant: "standard",
                label: "Check-in",
                margin: "none",
              },
            }}
          />

          <DatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            autoOk
            label="Check-out"
            value={checkOutDate || null}
            onChange={(newValue) => {
              setStay(id, { checkOutDate: cleanDateValue(newValue) });
            }}
            timezone="UTC"
            showDaysOutsideCurrentMonth
            format="dd/MM/yy"
            maxDate={endDateMaxValue}
            minDate={checkInDate ? addDays(checkInDate, 1) : bookingDateMinValue}
            referenceDate={referenceDate || createdAt}
            slotProps={{
              openPickerButton: { size: "small" },
              openPickerIcon: { fontSize: "small" },
              textField: {
                variant: "standard",
                label: "Check-out",
                margin: "none",
              },
            }}
          />

          <TextField
            label="Nights"
            disabled
            value={nights || ""}
            onChange={() => {}}
            variant="standard"
            margin="none"
            sx={{ maxWidth: 48 }}
            error={nights !== undefined && nights <= 0}
          />

          <TextField
            label="Booking Reference"
            value={bookingReference}
            onChange={(event) => {
              setStay(id, { bookingReference: event.target.value });
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            variant="standard"
            margin="none"
          />

          <TextField
            label="Room Type"
            value={roomName || ""}
            onChange={(event) => {
              setStay(id, { roomName: event.target.value });
            }}
            variant="standard"
            margin="none"
          />

          <TextField
            label="Room Number"
            value={roomNumber || ""}
            onChange={(event) => {
              setStay(id, { roomNumber: event.target.value });
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            variant="standard"
            margin="none"
          />
          <Divider orientation="vertical" variant="middle" flexItem />
          <Box sx={{ alignSelf: "center" }}>
            <LoyaltyProgramActivityButton
              title={getStayFullTitle(stay)}
              onChange={onLoyaltyProgramActivityChange}
              loyaltyProgramActivities={stay.loyaltyProgramActivities}
            />
          </Box>

          <Box sx={{ alignSelf: "center" }}>
            <EditorRowActionsButton value={stay} set={setStay} onDelete={onDeleteClick} />
          </Box>
        </Stack>
      </CardContent>
      <Divider />
    </Card>
  );
}
