export default function getSegmentStationIds(segments) {
  if (!segments || !segments.length) return [];
  return [
    ...segments.reduce((acc, segment) => {
      if (segment.origin) acc.add(segment.origin.id);
      if (segment.destination) acc.add(segment.destination.id);
      return acc;
    }, new Set()),
  ];
}
