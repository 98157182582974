import React from "react";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AgentsProvider from "../../contexts/Agents/AgentsProvider.js";
import CarriersProvider from "../../contexts/Carriers/CarriersProvider.js";
import CurrencyProvider from "../../contexts/Currency/CurrencyProvider.js";
import DialogProvider from "../../contexts/Dialog/DialogProvider.js";
import PaymentMethodsProvider from "../../contexts/PaymentMethods/PaymentMethodsProvider.js";
import SnackbarProvider from "../../contexts/SnackBar/SnackBarProvider.js";
import StationsProvider from "../../contexts/Stations/StationsProvider.js";
import StayBrandsProvider from "../../contexts/StayBrands/StayBrandsProvider.js";
import LoyaltyProgramsProvider from "../../contexts/LoyaltyPrograms/LoyaltyProgramsProvider.js";
import StayLocationsProvider from "../../contexts/StayLocations/StayLocationsProvider.js";
import AppInstallationProvider from "../../contexts/AppInstallation/AppInstallationProvider.js";
import StorageManagerProvider from "../../contexts/StorageManager/StorageManagerProvider.js";
import TripsProvider from "../../contexts/Trips/TripsProvider";
import theme from "./theme.js";
import Body from "./Body.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { enGB } from "date-fns/locale/en-GB";
import { IntlProvider } from "react-intl";

export default function App({ cache, serviceWorkerConnector }) {
  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <StorageManagerProvider>
          <AppInstallationProvider serviceWorkerConnector={serviceWorkerConnector}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
              <IntlProvider defaultLocale="en-GB" locale="en-GB" timeZone="UTC">
                <CssBaseline />
                <SnackbarProvider>
                  <DialogProvider>
                    <CarriersProvider>
                      <PaymentMethodsProvider>
                        <StayBrandsProvider>
                          <StayLocationsProvider>
                            <AgentsProvider>
                              <StationsProvider>
                                <CurrencyProvider>
                                  <LoyaltyProgramsProvider>
                                    <TripsProvider>
                                      <Body />
                                    </TripsProvider>
                                  </LoyaltyProgramsProvider>
                                </CurrencyProvider>
                              </StationsProvider>
                            </AgentsProvider>
                          </StayLocationsProvider>
                        </StayBrandsProvider>
                      </PaymentMethodsProvider>
                    </CarriersProvider>
                  </DialogProvider>
                </SnackbarProvider>
              </IntlProvider>
            </LocalizationProvider>
          </AppInstallationProvider>
        </StorageManagerProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
