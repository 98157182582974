import Typography from "@mui/material/Typography";
import React from "react";
import { CANCELLED, REFUNDED } from "../../models/BookingConstants.js";

export default function StatusLabel({ booking, inline }) {
  const sx = inline ? { paddingLeft: 1 } : { paddingRight: 1 };

  if (booking.status === CANCELLED) {
    const color = booking.isPaid ? "error.main" : "info.main";
    return (
      <Typography component="span" variant="body2" color={color} sx={sx}>
        Cancelled
      </Typography>
    );
  }
  if (booking.status === REFUNDED) {
    return (
      <Typography component="span" variant="body2" color="info.main" sx={sx}>
        Refunded
      </Typography>
    );
  }
  return null;
}
