import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import Card from "@mui/material/Card";
import StationsTable from "./StationsTable.js";

export default function StationsList() {
  const stations = useLiveQuery(() => db.stations.orderBy("title").toArray());

  if (!stations) return null;

  return (
    <Card square>
      <StationsTable stations={stations} />
    </Card>
  );
}
