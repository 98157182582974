/**
 *
 * @param {Date} value
 * @return {Date}
 */
export default function cleanDateValue(value) {
  if (!value) return value;
  const newValue = new Date(value);
  newValue.setUTCHours(0, 0, 0, 0);

  return newValue;
}
