import React from "react";
import IconButton from "@mui/material/IconButton";
import { Link as ReactRouterLink } from "react-router-dom";
import PublicIcon from "@mui/icons-material/Public";

export default function GlobalButton() {
  return (
    <>
      <IconButton component={ReactRouterLink} to="/globe" color="inherit" size="small">
        <PublicIcon />
      </IconButton>
    </>
  );
}
