import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { useIntl } from "react-intl";
import FeatureCard from "../FeatureCard.js";

export default function TripsListSummaryHeader({ state }) {
  const { formatNumber } = useIntl();

  return (
    <CardContent>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Card elevation={0}></Card>
        </Grid>
        <Grid item xs={2}>
          <FeatureCard title="Total Nights" value={formatNumber(state.totalNights || 0)} variant="outlined" />
        </Grid>

        <Grid item xs={2}>
          <FeatureCard
            title="Payments Due"
            value={formatNumber(state.totalPricePaidUnpaid, { style: "currency", currency: "GBP" })}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={2}>
          <FeatureCard
            title="Total Paid"
            value={formatNumber(state.totalPricePaid, { style: "currency", currency: "GBP" })}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}
