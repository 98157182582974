import React from "react";
import MainScrollLayout from "../MainScrollLayout.js";
import StationsList from "./StationsList.js";

export default function StationsListPage() {
  return (
    <MainScrollLayout>
      <StationsList />
    </MainScrollLayout>
  );
}
