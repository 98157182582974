import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import { Link as ReactRouterLink } from "react-router-dom";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import MainScrollLayout from "../MainScrollLayout.js";

export default function PageNotFound() {
  const { enqueueSnackbar } = useSnackBar();

  const action = (
    <Button
      startIcon={<CloseIcon fontSize="small" />}
      color="error"
      variant="outlined"
      component={ReactRouterLink}
      to="/"
    >
      Close
    </Button>
  );
  useEffect(() => {
    enqueueSnackbar({ message: "Page not found", variant: "error" });
  }, []);

  return (
    <MainScrollLayout>
      <CardContent>
        <Alert severity="error" action={action}>
          <AlertTitle>Page not found</AlertTitle>There was no page found at this URL.
        </Alert>
      </CardContent>
    </MainScrollLayout>
  );
}
