import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import Card from "@mui/material/Card";
import AgentsTable from "./AgentsTable.js";

export default function AgentsList() {
  const agents = useLiveQuery(() => db.agents.toCollection().sortBy("id"));

  if (!agents) return null;

  return (
    <Card square>
      <AgentsTable agents={agents} />
    </Card>
  );
}
