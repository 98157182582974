import TextField from "@mui/material/TextField";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import useCurrency from "../../contexts/Currency/useCurrency.js";

export default function CurrencySelectField({ value, onChange, disabled }) {
  const { currency } = useCurrency();

  const isEmpty = currency.length === 0;
  return (
    <TextField
      select
      label="Currency"
      variant="outlined"
      margin="none"
      size="small"
      autoComplete="off"
      fullWidth
      value={value || ""}
      onChange={onChange}
      error={isEmpty}
      disabled={disabled || isEmpty}
      SelectProps={{
        MenuProps: {
          MenuListProps: {
            dense: true,
          },
        },
      }}
    >
      {currency.map((currencyType) => (
        <MenuItem key={currencyType.id} value={currencyType.id}>
          {currencyType.title}
        </MenuItem>
      ))}
    </TextField>
  );
}
