export default function ServiceWorkerEventReducer(state, action) {
  const updated = { ...state, history: [action.type, ...state.history] };
  if (action.service) {
    return updated;
  }

  if (action.type === "registration-complete" && !updated.installed) {
    // This is thrown in isolation.
    // This isn't an update
    // This was already installed
    updated.installChecked = true;
    updated.installed = true;
  } else if (action.type === "unsupported") {
    // The platform won't use a worker
    updated.installChecked = true;
    updated.installed = false;
    updated.unsupported = true;
  } else if (action.type === "installed") {
    // The platform won't use a worker
    updated.installChecked = true;
    updated.installed = true;
  } else if (action.type === "waiting" || action.type === "externalwaiting") {
    // A reload/skipWait is required to activate the new version
    updated.updateAvailable = true;
  } else if (action.type === "skipWait:started") {
    updated.updateInstalling = true;
  } else if (action.type === "desktop-install-available") {
    updated.desktopInstallAvailable = true;
  } else if (action.type === "desktop-install-status") {
    updated.isDesktopInstallStatusKnown = true;
    updated.isDesktopInstalled = action.event.installed;
  } else if (action.type === "desktop-install-prompting") {
    updated.desktopInstallPrompting = true;
  } else if (action.type === "desktop-install-prompted") {
    updated.desktopInstallPrompting = false;
  } else if (action.type === "push-notifications-subscribed") {
    updated.pushNotificationSubscription = action.event.subscription;
    updated.lastPushNotificationSubscription = null;
  } else if (action.type === "push-notifications-not-subscribed") {
    updated.pushNotificationSubscription = null;
    updated.lastPushNotificationSubscription = null;
  } else if (action.type === "push-notifications-unsubscribed" && action.event.success) {
    updated.pushNotificationSubscription = null;
    updated.lastPushNotificationSubscription = state.pushNotificationSubscription;
  }

  return updated;
}
