import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { isPast } from "date-fns";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

export default function LoyaltyProgramActivityStatusIcon({ loyaltyProgramActivity }) {
  const { isPending, isCleared, clearDueDate } = loyaltyProgramActivity;

  if (isCleared) {
    return <CheckIcon color="success" />;
  }

  if (isPending) {
    if (clearDueDate && isPast(clearDueDate)) {
      return <ErrorIcon color="error" />;
    }
    return <HourglassTopIcon color="info" />;
  }

  if (clearDueDate && isPast(clearDueDate)) {
    return <ErrorIcon color="error" />;
  }

  return <ErrorIcon color="warning" />;
}
