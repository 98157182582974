import React, { Component } from "react";
import { createPortal } from "react-dom";
import SnackbarContext from "./SnackBarContext.js";
import { isDefined } from "./utils.js";
import { defaults, merge } from "./merger.js";
import createChainedFunction from "./createChainedFunction.js";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export let enqueueSnackbar;
export let closeSnackbar;

class SnackbarProvider extends Component {
  constructor(props) {
    super(props);
    enqueueSnackbar = this.enqueueSnackbar;
    closeSnackbar = this.closeSnackbar;

    this.state = {
      snacks: [],
      queue: [],
      contextValue: {
        enqueueSnackbar: this.enqueueSnackbar.bind(this),
        closeSnackbar: this.closeSnackbar.bind(this),
      },
    };
  }

  get maxSnack() {
    return this.props.maxSnack || defaults.maxSnack;
  }

  /**
   * Adds a new snackbar to the queue to be presented.
   * Returns generated or user defined key referencing the new snackbar or null
   */
  enqueueSnackbar = (messageOrOptions) => {
    const opts = messageOrOptions;
    const { message } = messageOrOptions;

    const { key, preventDuplicate, ...options } = opts;

    const hasSpecifiedKey = isDefined(key);
    const id = hasSpecifiedKey ? key : new Date().getTime() + Math.random();

    const merger = merge(options, this.props);
    const snack = {
      id,
      ...options,
      message,
      open: true,
      entered: false,
      requestClose: false,
      persist: merger("persist"),
      action: merger("action"),
      content: merger("content"),
      variant: merger("variant"),
      disableWindowBlurListener: merger("disableWindowBlurListener"),
      autoHideDuration: merger("autoHideDuration"),
      SnackbarProps: merger("SnackbarProps", true),
    };

    if (snack.persist) {
      snack.autoHideDuration = undefined;
    }

    this.setState((state) => {
      if ((preventDuplicate === undefined && this.props.preventDuplicate) || preventDuplicate) {
        const compareFunction = (item) => (hasSpecifiedKey ? item.id === id : item.message === message);

        const inQueue = state.queue.findIndex(compareFunction) > -1;
        const inView = state.snacks.findIndex(compareFunction) > -1;
        if (inQueue || inView) {
          return state;
        }
      }

      return this.handleDisplaySnack({
        ...state,
        queue: [...state.queue, snack],
      });
    });

    return id;
  };

  /**
   * Reducer: Display snack if there's space for it. Otherwise, immediately
   * begin dismissing the oldest message to start showing the new one.
   */
  handleDisplaySnack = (state) => {
    const { snacks } = state;
    if (snacks.length >= this.maxSnack) {
      return this.handleDismissOldest(state);
    }
    return this.processQueue(state);
  };

  /**
   * Reducer: Display items (notifications) in the queue if there's space for them.
   */
  processQueue = (state) => {
    const { queue, snacks } = state;
    if (queue.length > 0) {
      return {
        ...state,
        snacks: [...snacks, queue[0]],
        queue: queue.slice(1, queue.length),
      };
    }
    return state;
  };

  /**
   * Reducer: Hide oldest snackbar on the screen because there exists a new one which we have to display.
   * (ignoring the one with 'persist' flag. i.e. explicitly told by user not to get dismissed).
   *
   * Note 1: If there is already a message leaving the screen, no new messages are dismissed.
   * Note 2: If the oldest message has not yet entered the screen, only a request to close the
   *         snackbar is made. Once it entered the screen, it will be immediately dismissed.
   */
  handleDismissOldest = (state) => {
    if (state.snacks.some((item) => !item.open || item.requestClose)) {
      return state;
    }

    let popped = false;
    let ignore = false;

    const persistentCount = state.snacks.reduce((acc, current) => acc + (current.open && current.persist ? 1 : 0), 0);

    if (persistentCount === this.maxSnack) {
      console.warn("NO_PERSIST_ALL");
      ignore = true;
    }

    const snacks = state.snacks.map((item) => {
      if (!popped && (!item.persist || ignore)) {
        popped = true;

        if (!item.entered) {
          return {
            ...item,
            requestClose: true,
          };
        }

        if (item.onClose) {
          item.onClose(null, "maxsnack", item.id);
        }

        if (this.props.onClose) {
          this.props.onClose(null, "maxsnack", item.id);
        }

        return {
          ...item,
          open: false,
        };
      }

      return { ...item };
    });

    return { ...state, snacks };
  };

  /**
   * Set the entered state of the snackbar with the given key.
   */
  handleEnteredSnack = (node, isAppearing, key) => {
    if (!isDefined(key)) {
      throw new Error("handleEnteredSnack Cannot be called with undefined key");
    }

    this.setState(({ snacks }) => ({
      snacks: snacks.map((item) => (item.id === key ? { ...item, entered: true } : { ...item })),
    }));
  };

  /**
   * Hide a snackbar after its timeout.
   */
  handleCloseSnack = (event, reason, key) => {
    // should not use createChainedFunction for onClose.
    // because this.closeSnackbar called this function
    if (this.props.onClose) {
      this.props.onClose(event, reason, key);
    }

    const shouldCloseAll = key === undefined;

    this.setState(({ snacks, queue }) => ({
      snacks: snacks.map((item) => {
        if (!shouldCloseAll && item.id !== key) {
          return { ...item };
        }

        return item.entered ? { ...item, open: false } : { ...item, requestClose: true };
      }),
      queue: queue.filter((item) => item.id !== key),
    }));
  };

  /**
   * Close snackbar with the given key
   */
  closeSnackbar = (key) => {
    // call individual snackbar onClose callback passed through options parameter
    const toBeClosed = this.state.snacks.find((item) => item.id === key);
    if (isDefined(key) && toBeClosed && toBeClosed.onClose) {
      toBeClosed.onClose(null, "instructed", key);
    }

    this.handleCloseSnack(null, "instructed", key);
  };

  /**
   * When we set open attribute of a snackbar to false (i.e. after we hide a snackbar),
   * it leaves the screen and immediately after leaving animation is done, this method
   * gets called. We remove the hidden snackbar from state and then display notifications
   * waiting in the queue (if any). If after this process the queue is not empty, the
   * oldest message is dismissed.
   */
  handleExitedSnack = (node, key) => {
    if (!isDefined(key)) {
      throw new Error("handleExitedSnack Cannot be called with undefined key");
    }

    this.setState((state) => {
      const newState = this.processQueue({
        ...state,
        snacks: state.snacks.filter((item) => item.id !== key),
      });

      if (newState.queue.length === 0) {
        return newState;
      }

      return this.handleDismissOldest(newState);
    });
  };

  render() {
    const { contextValue, snacks } = this.state;
    const { domRoot, children = false } = this.props;

    const snackbars = (
      <>
        {snacks.map((snack) => (
          <Snackbar
            key={snack.id}
            open={snack.open}
            TransitionComponent={Slide}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={snack.autoHideDuration}
            TransitionProps={{
              onExited: createChainedFunction([this.handleExitedSnack, this.props.onExited], snack.id),
              onEntered: createChainedFunction([this.handleEnteredSnack, this.props.onEntered], snack.id),
              onExit: this.props.onExit,
              onEnter: this.props.onEnter,
            }}
            onClose={this.handleCloseSnack}
          >
            <Alert severity={snack.variant} variant="filled" onClose={this.handleCloseSnack}>
              {snack.title && <AlertTitle>{snack.title}</AlertTitle>}
              {snack.message}
            </Alert>
          </Snackbar>
        ))}
      </>
    );

    return (
      <SnackbarContext.Provider value={contextValue}>
        {children}
        {domRoot ? createPortal(snackbars, domRoot) : snackbars}
      </SnackbarContext.Provider>
    );
  }
}

export default SnackbarProvider;
