import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import { db } from "../../models/db.js";
import StayLocationsContext from "./StayLocationsContext.js";

export default function StayLocationsProvider({ children }) {
  const stayLocations = useLiveQuery(() => db.stayLocations.toCollection().sortBy("id"));
  const isLoaded = stayLocations !== undefined;
  return (
    <StayLocationsContext.Provider value={{ stayLocations: stayLocations || [], isLoaded }}>
      {children}
    </StayLocationsContext.Provider>
  );
}
