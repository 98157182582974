import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { extractAsEJSON } from "../../models/extractToJSON.js";

const fullFileName = "export.json";

const saveToFile = (fileContent) => {
  if (!fileContent) return;
  const fileParts = [fileContent];
  const blobLink = document.createElement("a");
  const blob = new Blob(fileParts, { type: "text/json" });
  blobLink.style.display = "none";
  const blobUrl = window.URL.createObjectURL(blob);

  blobLink.setAttribute("href", blobUrl);
  blobLink.setAttribute("download", fullFileName);
  blobLink.click();
};

export default function ExportDataMenuItem({ onClick }) {
  const onClickHandler = async () => {
    onClick();
    const result = await extractAsEJSON();
    saveToFile(result);
  };
  return (
    <MenuItem onClick={onClickHandler}>
      <ListItemIcon>
        <DownloadIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Export Data</ListItemText>
    </MenuItem>
  );
}
