function formatRow(values) {
  return `| ${values.join(" | ")} |`;
}

export default function formatBookingsToMarkdown(bookings) {
  const fieldNames = [
    "Departure Date",
    "Flight Number",
    "Carrier",
    "Class",
    "Origin IATA Airport Code",
    "Origin Airport Name",
    "Destination IATA Airport Code",
    "Destination Airport Name",
  ];

  const line = [
    formatRow(fieldNames),
    ...bookings
      .map((booking) => {
        return booking.segments
          .map((segment) => {
            if (segment.carrier.type !== "FLIGHT") return null;
            const fields = [
              segment.departureDate.toISOString(),
              segment.flightNumber || "",
              segment.carrier.title,
              segment.classCode || "",
              segment.origin.id || "",
              segment.origin.title || "",
              segment.destination.id || "",
              segment.destination.title || "",
            ];
            return formatRow(fields);
          })
          .filter((item) => item !== null);
      })
      .flat(),
  ];

  return line.join("\n");
}
