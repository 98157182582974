import React from "react";
import MainScrollLayout from "../MainScrollLayout.js";
import CarriersList from "./CarriersList.js";

export default function CarriersListPage() {
  return (
    <MainScrollLayout>
      <CarriersList />
    </MainScrollLayout>
  );
}
