function calculateTotals(trips) {
  const result = trips.reduce(
    (acc, trip) => {
      acc.totalPricePaid += trip.totalPricePaid || 0.0;
      acc.totalPricePaidUnpaid += trip.totalPricePaidUnpaid || 0.0;
      acc.totalNights += trip.totalNights || 0;
      return acc;
    },
    {
      totalPricePaid: 0.0,
      totalPricePaidUnpaid: 0.0,
      totalNights: 0,
      totalPricePaidForServices: 0.0,
      totalPricePaidForStays: 0.0,
      totalPricePaidForTravel: 0.0,
    },
  );

  return result;
}

export default function TripsListReducer(state, action) {
  if (!action) return state;
  const { value, type } = action;

  if (type === "SET_STATE") {
    return { ...state, ...value };
  }

  if (type === "SET_TRIPS") {
    return { ...state, trips: value, ...calculateTotals(value) };
  }

  return state;
}
