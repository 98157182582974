import Joi from "joi";
import generateIdFromTitle from "../lib/generateIdFromTitle.js";

export const LoyaltyProgramValidator = Joi.object({
  title: Joi.string().required(),
  id: Joi.string().required(),
  sources: Joi.array().items(Joi.string().required()).required(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  programId: Joi.string().optional(),
  note: Joi.string().optional(),
}).label("Loyalty Program");

/**
 * Represents a Loyalty Program, which can accumulate points or miles.
 */
export default class LoyaltyProgram {
  constructor({ id, title, _id, createdAt, updatedAt, sources, programId, note } = {}) {
    const candidateId = id || _id;
    this.id = generateIdFromTitle(candidateId || title);
    this.title = title || undefined;
    this.createdAt = createdAt || undefined;
    this.updatedAt = updatedAt || undefined;
    this.sources = sources || [];
    this.programId = programId || undefined;
    this.note = note || undefined;
  }

  /**
   * The title.
   * @type {String}
   */
  title;

  /**
   * The program assigned ID.
   * @type {String}
   */
  programId;

  /**
   * Any description / note text.
   * @type {String}
   */
  note;

  /**
   * The creation date.
   * @type {Date}
   */
  createdAt;

  /**
   * The update date.
   * @type {Date}
   */
  updatedAt;

  /**
   * The ID
   * @type {String}
   */
  id;

  /**
   * The names of specific sources
   * @type {String[]}
   */
  sources;

  /**
   * Indicates if this Agent and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = LoyaltyProgramValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
