export const BUS = "BUS";
export const TRAIN = "TRAIN";
export const BOAT = "BOAT";
export const CAR = "CAR";
export const FLIGHT = "FLIGHT";
export const OTHER = "OTHER";

export const CARRIER_TYPES = {
  BUS,
  TRAIN,
  BOAT,
  CAR,
  FLIGHT,
  OTHER,
};
