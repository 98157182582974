import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import GenericBookingsTable from "../GenericBookingsTable/GenericBookingsTable.js";

export default function AgentBookingsList({ agentId, linkState }) {
  const bookings = useLiveQuery(() => db.bookings.where("agent.id").equals(agentId).reverse().sortBy("bookingDate"));

  return (
    <GenericBookingsTable
      bookings={bookings}
      isLoading={bookings === undefined}
      showTrip
      hideAgentLink
      linkState={linkState}
    />
  );
}
