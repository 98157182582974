import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import React from "react";

export default function FixedFooterAppBar({ children }) {
  return (
    <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0 }} component="footer">
      <Toolbar>
        <Stack spacing={1} direction="row">
          {children}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
