import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Save from "@mui/icons-material/Save";
import React from "react";
import LoyaltyProgramActivityRow from "./LoyaltyProgramActivityRow.js";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";

export default function LoyaltyProgramActivityModal({
  isOpen,
  onClose,
  title,
  set,
  loyaltyProgramActivities,
  referenceDate,
  minDate,
}) {
  const onPrimaryClick = async () => {
    onClose();
  };

  const onCloseClick = () => {
    onClose();
  };

  const setLoyaltyProgramActivity = (value, type) => {
    set(value, type);
  };

  const activityCount = loyaltyProgramActivities?.length || 0;
  const hasActivities = activityCount > 0;
  const isSingleActivity = activityCount === 1;
  const hasMultipleActivities = hasActivities && activityCount > 1;

  const deleteActivity = (id) => setLoyaltyProgramActivity({ id }, "DELETE_LOYALTY_PROGRAM_ACTIVITY");

  const onDeleteSingleActivity = () => {
    deleteActivity(loyaltyProgramActivities[0].loyaltyProgram.id);
  };

  return (
    <Dialog open={isOpen} onClose={onCloseClick} fullWidth maxWidth={"md"}>
      <DialogTitle>{title || "Loyalty Programs"}</DialogTitle>
      <Divider />
      <DialogContent>
        {hasActivities &&
          loyaltyProgramActivities.map((loyaltyProgramActivity) => (
            <LoyaltyProgramActivityRow
              key={loyaltyProgramActivity.loyaltyProgram.id}
              loyaltyProgramActivity={loyaltyProgramActivity}
              setLoyaltyProgramActivity={setLoyaltyProgramActivity}
              referenceDate={referenceDate}
              minDate={minDate}
              deleteActivity={deleteActivity}
            />
          ))}
        {!hasActivities && <Alert severity="info">No loyalty program activity has been added.</Alert>}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: "flex-start" }}>
        <Button onClick={onPrimaryClick} startIcon={<Save />} color="primary" variant="contained">
          Update
        </Button>
        <Button startIcon={<CloseIcon />} onClick={onClose} color="secondary" variant="outlined">
          Close
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem />

        {isSingleActivity && (
          <Button
            startIcon={<DeleteForeverIcon />}
            disabled={hasMultipleActivities}
            color="error"
            variant="outlined"
            onClick={onDeleteSingleActivity}
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
