import Typography from "@mui/material/Typography";
import React from "react";
import { useIntl } from "react-intl";
import { CANCELLED, REFUNDED } from "../../models/BookingConstants.js";

export default function CostText({ booking }) {
  const { formatNumber } = useIntl();

  const { pricePaid, status, isPaid } = booking;

  if (pricePaid !== undefined) {
    if (status === REFUNDED || (status === CANCELLED && !isPaid)) {
      return (
        <Typography
          variant="body2"
          color={booking.isPriceEstimated ? "" : undefined}
          sx={{
            textDecoration: "line-through",
          }}
        >
          {formatNumber(booking.pricePaid, { style: "currency", currency: "GBP" })}
        </Typography>
      );
    }
    return (
      <Typography
        variant="body2"
        fontStyle={booking.isPriceEstimated ? "italic" : undefined}
        color={booking.isPriceEstimated ? "textSecondary" : undefined}
      >
        {formatNumber(booking.pricePaid, { style: "currency", currency: "GBP" })}
      </Typography>
    );
  }
  return (
    <Typography variant="body2" color="warning.main" component="span" fontStyle="italic">
      Unknown
    </Typography>
  );
}
