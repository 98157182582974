import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import React from "react";

import GenericBookingsRow from "./GenericBookingsRow.js";

export default function GenericBookingsTable({
  bookings,
  showTrip,
  hideAgentLink,
  isTravel,
  isStay,
  linkState,
  isLoading,
}) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel active direction="desc" disabled>
                Booking Date
              </TableSortLabel>
            </TableCell>
            <TableCell>Description</TableCell>
            <TableCell>
              {"Supplier"}
              {hideAgentLink !== true && " / Vendor"}
            </TableCell>
            <TableCell>Reference</TableCell>
            <TableCell align="right">Cost</TableCell>
            <TableCell align="right">Price Paid</TableCell>
            {showTrip && <TableCell>Trip</TableCell>}
            {showTrip && <TableCell colSpan={3}>Loyalty</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings?.map((booking) => (
            <GenericBookingsRow
              key={booking.id}
              booking={booking}
              linkState={linkState}
              hideAgentLink={hideAgentLink}
              isTravel={isTravel}
              isStay={isStay}
              showTrip={showTrip}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
