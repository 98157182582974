import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import { db } from "../../models/db.js";
import PaymentMethodsContext from "./PaymentMethodsContext.js";

export default function PaymentMethodsProvider({ children }) {
  const paymentMethods = useLiveQuery(() => db.paymentMethods.toCollection().reverse().sortBy("id"));

  return (
    <PaymentMethodsContext.Provider value={{ paymentMethods: paymentMethods || [] }}>
      {children}
    </PaymentMethodsContext.Provider>
  );
}
