import { createContext } from "react";

const AppInstallationContext = createContext({
  isInstalled: undefined,
  serverAdvertisedVersion: undefined,
  serverAdvertisedBuildDate: undefined,
  serverAdvertisedHash: undefined,
  clientVersion: undefined,
  clientBuildDate: undefined,
  installChecked: undefined,
  canBeInstalled: undefined,
  setInstalled: () => {},
  setInstalledError: () => {},
  setDesktopInstalled: () => {},
  setDesktopInstallAvailable: () => {},
  setServerAdvertisedVersion: () => {},
  appInstallState: undefined,
  recachePreloads: async () => {},
});

export default AppInstallationContext;
