import { createContext } from "react";

const noOp = () => {
  return "";
};

export default createContext({
  enqueueSnackbar: noOp,
  closeSnackbar: noOp,
});
