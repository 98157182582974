import Box from "@mui/material/Box";
import React from "react";

export default function SubScrollLayout({ children }) {
  return (
    <Box
      sx={{
        flexGrow: "1",
        overflowY: "scroll",
        overscrollBehaviorY: "contain",
      }}
    >
      {children}
    </Box>
  );
}
