/**
 * Indicates if the flights on a trip, if there are multiple, are all connected without changing airports during layover.
 * It helps to compact the title string used to display such bookings.
 * @return {boolean}
 */
export default function isConnectedTravelSequence(booking) {
  if (!booking.segments || !booking.segments.length) return false;
  return booking.segments.reduce(
    (acc, segment) => {
      if (acc.connected === false) return acc;
      if (!acc.previousStationId) {
        acc.previousStationId = segment.destination.id;
        return acc;
      }
      if (segment.origin.id === acc.previousStationId) {
        // this segment origin matches previous segment destination
        acc.previousStationId = segment.destination.id;
        acc.connected = true;
        return acc;
      }

      acc.connected = false;

      return acc;
    },
    { previousStationId: undefined, connected: undefined },
  ).connected;
}
