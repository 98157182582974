import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App/App.js";
import createEmotionCache from "./lib/createEmotionCache.js";
import { BrowserRouter } from "react-router-dom";
import setupServiceWorker from "./lib/serviceWorker/setupServiceWorker.js";
// import setupWebWorker from "./lib/webWorker/setupWebWorker.js";

const serviceWorkerConnector = setupServiceWorker();

// const webWorkerConnector = setupWebWorker();

const cache = createEmotionCache();

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <BrowserRouter>
      <App cache={cache} serviceWorkerConnector={serviceWorkerConnector} />
    </BrowserRouter>
  </StrictMode>,
);
