import Joi from "joi";
import generateIdFromTitle from "../lib/generateIdFromTitle.js";

export const StationValidator = Joi.object({
  title: Joi.string().required(),
  id: Joi.string().required().uppercase().min(3).max(3),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  icao: Joi.string().optional().uppercase().min(4).max(4),
  lat: Joi.number().optional(),
  lon: Joi.number().optional(),
  timeZone: Joi.string().optional(),
  shortName: Joi.string().optional(),
  fullName: Joi.string().optional(),
  municipalityName: Joi.string().optional(),
  country: Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required(),
  }),
  continent: Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required(),
  }),
  hasData: Joi.boolean().optional(),
}).label("Station");

/**
 * Represents a Station, which can be an airport, or train station etc. It's a departure point.
 */
export default class Station {
  constructor({
    id,
    title,
    createdAt,
    updatedAt,
    icao,
    lat,
    lon,
    timeZone,
    country,
    continent,
    shortName,
    fullName,
    municipalityName,
    hasData,
  } = {}) {
    this.id = id || generateIdFromTitle(title).substring(0, 3);
    this.title = title || undefined;
    this.createdAt = createdAt || undefined;
    this.updatedAt = updatedAt || undefined;
    this.icao = icao || undefined;
    this.timeZone = timeZone || undefined;
    this.lat = lat ?? undefined;
    this.lon = lon ?? undefined;
    this.country = country || undefined;
    this.continent = continent || undefined;
    this.shortName = shortName || undefined;
    this.fullName = fullName || undefined;
    this.municipalityName = municipalityName || undefined;
    this.hasData = hasData;
  }

  /**
   * The title of this station.
   * @type {String}
   */
  title;

  /**
   * The Station ID - this should be the IATA (three letter) airport code
   * @type {String}
   */
  id;

  /**
   * The station ID - this is the four letter ICAO code.
   * @type {String|undefined}
   */
  icao;

  /**
   * The short name
   * @type {String|undefined}
   */
  shortName;
  /**
   * The long name
   * @type {String|undefined}
   */
  fullName;

  /**
   * The city name, such as 'São Paulo'.
   * @type {String|undefined}
   */
  municipalityName;

  /**
   * The latitude.
   * @type {Number|undefined}
   */
  lat;

  /**
   * The longitude.
   * @type {Number|undefined}
   */
  lon;

  /**
   * The timezone identifier
   * @type {String|undefined}
   */
  timeZone;

  hasData;

  /**
   * The country
   * @type {{code: String, name: String}}|undefined}
   */
  country;

  /**
   * The continent
   * @type {{code: String, name: String}}|undefined}
   */
  continent;

  /**
   * The creation date.
   * @type {Date}
   */
  createdAt;

  /**
   * The update date.
   * @type {Date}
   */
  updatedAt;

  /**
   * Indicates if this Station and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = StationValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
