import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import { TRAVEL } from "../../models/BookingConstants.js";
import GenericBookingsTable from "../GenericBookingsTable/GenericBookingsTable.js";

export default function BookingTravelList() {
  const bookings = useLiveQuery(() => db.bookings.where("type").equals(TRAVEL).reverse().sortBy("bookingDate"));

  return <GenericBookingsTable bookings={bookings} isLoading={bookings === undefined} showTrip isTravel />;
}
