import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";
import { Link as ReactRouterLink } from "react-router-dom";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import stripWhiteSpace from "../../lib/stripWhiteSpace.js";
import { BUS, TRAIN, BOAT, CAR, FLIGHT } from "../../models/CarrierConstants.js";
import { db } from "../../models/db.js";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function CarrierRow({ carrier }) {
  const { enqueueSnackbar } = useSnackBar();
  const { formatNumber } = useIntl();

  const [editMode, setEditMode] = useState(false);
  const [type, setType] = useState(carrier.type || "UNKNOWN");
  const [title, setTitle] = useState(carrier.title);
  const [id, setId] = useState(carrier.id);

  const onEditClick = () => {
    setEditMode(!editMode);
  };

  const onDeleteClick = async () => {
    const result = await db.deleteCarrier(carrier.id);
    if (result?.success) {
      enqueueSnackbar({ message: `${title} Deleted`, variant: "success" });
    } else {
      enqueueSnackbar({ message: result.message, variant: "error" });
    }
  };

  const onCancelClick = () => {
    setEditMode(false);
    setType(carrier.type);
    setTitle(carrier.title);
    setId(carrier.id);
  };

  const onSaveClick = async () => {
    // TODO: Force update anyway, later make updates only on actual edits
    // if (type !== carrier.type || title !== carrier.title) {
    await db.updateCarrier(
      {
        ...carrier,
        id,
        title,
        type: type === "UNKNOWN" ? undefined : type,
      },
      carrier,
    );
    enqueueSnackbar({ message: `${title} Updated`, variant: "success" });
    // }
    setEditMode(!editMode);
  };

  const onTypeChange = (event) => {
    setType(event.target.value);
  };

  const onTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const onIdChange = (event) => {
    setId(stripWhiteSpace(event.target.value.toUpperCase()));
  };

  const onRecalculateLifetimeSpendClick = async () => {
    await db.recalculateCarrier(carrier.id);
    enqueueSnackbar({ message: `${title} Spend Updated`, variant: "success" });
  };

  return (
    <TableRow hover>
      {!editMode && <TableCell>{carrier.id}</TableCell>}
      {editMode && (
        <TableCell>
          <TextField value={id} fullWidth margin="none" required onChange={onIdChange} />
        </TableCell>
      )}
      {!editMode && (
        <TableCell>
          <Link to={`/carriers/${carrier.id}`} component={ReactRouterLink}>
            {carrier.title}
          </Link>
        </TableCell>
      )}
      {editMode && (
        <TableCell>
          <TextField value={title} fullWidth margin="none" required onChange={onTitleChange} />
        </TableCell>
      )}

      {!editMode && <TableCell>{carrier.type || "Unknown"}</TableCell>}
      {editMode && (
        <TableCell>
          <TextField
            select
            value={type}
            fullWidth
            margin="none"
            onChange={onTypeChange}
            SelectProps={{
              MenuProps: {
                MenuListProps: {
                  dense: true,
                },
              },
            }}
          >
            <MenuItem value="UNKNOWN">Unknown</MenuItem>
            <MenuItem value={BUS}>Bus</MenuItem>
            <MenuItem value={TRAIN}>Train</MenuItem>
            <MenuItem value={BOAT}>Boat</MenuItem>
            <MenuItem value={CAR}>Car</MenuItem>
            <MenuItem value={FLIGHT}>Flight</MenuItem>
          </TextField>
        </TableCell>
      )}
      <TableCell align="right">
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
          {carrier.lifetimeSpend !== undefined ? (
            <span>{formatNumber(carrier.lifetimeSpend, { style: "currency", currency: "GBP" })}</span>
          ) : (
            <span>Unknown</span>
          )}

          <IconButton size="small" disabled={editMode} color="inherit" onClick={onRecalculateLifetimeSpendClick}>
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Stack>
      </TableCell>

      <TableCell align="right">
        {editMode && (
          <Button color="secondary" onClick={onCancelClick}>
            Cancel
          </Button>
        )}
        {!editMode && (
          <Button color="secondary" onClick={onDeleteClick}>
            Delete
          </Button>
        )}
        {!editMode && (
          <Button color="secondary" onClick={onEditClick}>
            Edit
          </Button>
        )}
        {editMode && (
          <Button color="primary" onClick={onSaveClick}>
            Save
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}
