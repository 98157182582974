import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import React from "react";
import StationsTableRow from "./StationsTableRow.js";

export default function StationsTable({ stations }) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "20%" }}>Name</TableCell>
            <TableCell sx={{ width: "15%" }}>IATA</TableCell>
            <TableCell sx={{ width: "15%" }}>ICAO</TableCell>
            <TableCell sx={{ width: "25%" }}>Latitude / Longitude</TableCell>
            <TableCell sx={{ width: "25%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stations.map((station) => (
            <StationsTableRow key={station.id} station={station} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
