import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import { db } from "../../models/db.js";
import StationsContext from "./StationsContext.js";

export default function CarrierProvider({ children }) {
  const stations = useLiveQuery(() => db.stations.toCollection().sortBy("id"));

  const saveStation = async (station) => {
    if (!station.isValid) {
      return null;
    }
    return db.stations.add(station);
  };

  return (
    <StationsContext.Provider value={{ stations: stations || [], saveStation }}>{children}</StationsContext.Provider>
  );
}
