import React from "react";
import MainScrollLayout from "../MainScrollLayout.js";
import AIInterface from "./AIInterface.js";

export default function AIPage() {
  return (
    <MainScrollLayout>
      <AIInterface />
    </MainScrollLayout>
  );
}
