import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import Card from "@mui/material/Card";
import StayBrandsTable from "./StayBrandsTable.js";

export default function StayBrandsList() {
  const stayBrands = useLiveQuery(() => db.stayBrands.toCollection().sortBy("id"));

  if (!stayBrands) return null;

  return (
    <Card square>
      <StayBrandsTable stayBrands={stayBrands} />
    </Card>
  );
}
