import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Chip from "@mui/material/Chip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import React from "react";
import { useIntl } from "react-intl";
import ErrorIcon from "@mui/icons-material/Error";
import CopyToClipboardText from "../CopyToClipboardText.js";
import BookingDescriptionText from "../GenericBookingsTable/BookingDescriptionText.js";
import CostText from "../GenericBookingsTable/CostText.js";
import PricePaid from "../GenericBookingsTable/PricePaid.js";
import SupplierTitleLink from "../GenericBookingsTable/SupplierTitleLink.js";
import BookingPaymentStatusChip from "./BookingPaymentStatusChip.js";
import BookingTypeIcon from "./BookingTypeIcon.js";
import TableSortLabel from "@mui/material/TableSortLabel";
import BookingTitleLink from "../GenericBookingsTable/BookingTitleLink.js";

export default function BookingsTable({ bookings }) {
  const { formatDate, formatNumber } = useIntl();

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel active direction="desc" disabled>
                Booking Date
              </TableSortLabel>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Supplier / Vendor</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell align="right">Cost</TableCell>
            <TableCell align="right">Price Paid</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings.map((booking) => {
            return (
              <TableRow key={booking.id} hover>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {formatDate(booking.bookingDate, {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    weekday: "short",
                  })}
                </TableCell>
                <TableCell>
                  <BookingTypeIcon booking={booking} />
                </TableCell>
                <TableCell>
                  <BookingTitleLink booking={booking} />
                  <BookingDescriptionText booking={booking} />
                </TableCell>
                <TableCell>
                  <SupplierTitleLink booking={booking} />
                </TableCell>
                <TableCell>
                  <CopyToClipboardText value={booking.bookingReference} />
                </TableCell>
                <TableCell align="right">
                  <CostText booking={booking} />
                </TableCell>

                <TableCell align="right">
                  <PricePaid booking={booking} />
                </TableCell>
                <TableCell>
                  <BookingPaymentStatusChip booking={booking} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
