import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useIntl } from "react-intl";
import CopyToClipboardText from "../CopyToClipboardText.js";
import GenericLoyaltyProgramActivityBookingColumn from "../LoyaltyProgramActivityList/GenericLoyaltyProgramActivityBookingColumn.js";
import BookingDescriptionText from "./BookingDescriptionText.js";
import BookingTitleLink from "./BookingTitleLink.js";
import CostText from "./CostText.js";
import PricePaid from "./PricePaid.js";
import SupplierTitleLink from "./SupplierTitleLink.js";
import TextWrapper from "./TextWrapper.js";
import TripLink from "./TripLink.js";

export default function GenericBookingsRow({ booking, showTrip, linkState, hideAgentLink, isTravel, isStay }) {
  const { formatDate } = useIntl();

  return (
    <TableRow hover>
      <TableCell
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {formatDate(booking.bookingDate, {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
        })}
      </TableCell>
      <TableCell>
        <BookingTitleLink
          hideStationIds
          hideCarrier
          booking={booking}
          isTravel={isTravel}
          isStay={isStay}
          state={linkState}
        />
        <BookingDescriptionText booking={booking} />
      </TableCell>
      <TableCell>
        <SupplierTitleLink booking={booking} hideAgentLink={hideAgentLink} isTravel={isTravel} isStay={isStay} />
      </TableCell>
      <TableCell>
        <TextWrapper width="100px">
          <CopyToClipboardText
            value={booking.bookingReference}
            sx={{
              maxWidth: "inherit",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inline-block",
            }}
          />
        </TextWrapper>
      </TableCell>
      <TableCell align="right">
        <CostText booking={booking} />
      </TableCell>

      <TableCell align="right">
        <PricePaid booking={booking} />
      </TableCell>
      {showTrip && (
        <TableCell>
          <TextWrapper>
            <TripLink trip={booking.trip} />
          </TextWrapper>
        </TableCell>
      )}
      <GenericLoyaltyProgramActivityBookingColumn booking={booking} />
    </TableRow>
  );
}
