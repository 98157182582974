/**
 * @return {boolean}
 */
export function isReturnSegment(segments) {
  return (
    segments.length === 2 &&
    segments[0].origin.id === segments[1].destination.id &&
    segments[0].destination.id === segments[1].origin.id
  );
}

/**
 * @return {boolean}
 */
export default function isReturnBooking(booking) {
  if (!booking.segments || !booking.segments.length) return false;
  return isReturnSegment(booking.segments);
}
