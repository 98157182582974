import FlightIcon from "@mui/icons-material/Flight";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import React from "react";
import { ToggleButtonCompact, ToggleButtonIcon } from "../controls/ToggleButton.js";
import FixedFooterAppBar from "../FixedFooterAppBar.js";
import FlightsList from "../FlightsList/FlightsList.js";
import MainScrollLayout from "../MainScrollLayout.js";
import SubScrollLayout from "../SubScrollLayout.js";
import { CarrierDetailsCardQuery } from "./CarrierDetailsCard.js";
import CarriersBookingsList from "./CarriersBookingsList.js";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";

export default function CarriersBookingsListPage() {
  const { carrierId, tab } = useParams();
  const linkState = { source: "carrier" };

  const tabSafe = tab || "bookings";

  return (
    <>
      <MainScrollLayout footerBar fixed flexColumn>
        <CarrierDetailsCardQuery carrierId={carrierId} />
        <Divider />
        <SubScrollLayout key={tabSafe}>
          {tabSafe === "bookings" && <CarriersBookingsList carrierId={carrierId} linkState={linkState} />}
          {tabSafe === "flights" && <FlightsList carrierId={carrierId} linkState={linkState} />}
        </SubScrollLayout>
      </MainScrollLayout>
      <FixedFooterAppBar>
        <Button
          size="small"
          startIcon={<FlightIcon fontSize="small" />}
          color="secondary"
          variant="outlined"
          component={ReactRouterLink}
          state={linkState}
          to={`/bookings/new/travel?carrierId=${carrierId}`}
        >
          Add Travel
        </Button>
        <Divider orientation="vertical" variant="middle" flexItem />
        <ToggleButtonGroup exclusive value={tabSafe}>
          <ToggleButtonCompact
            color="primary"
            size="small"
            value="bookings"
            component={ReactRouterLink}
            to={`/carriers/${carrierId}`}
          >
            <ToggleButtonIcon>
              <ConfirmationNumberIcon />
            </ToggleButtonIcon>
            Bookings
          </ToggleButtonCompact>
          <ToggleButtonCompact
            color="primary"
            size="small"
            value="flights"
            component={ReactRouterLink}
            to={`/carriers/${carrierId}/flights`}
          >
            <ToggleButtonIcon>
              <ConnectingAirportsIcon />
            </ToggleButtonIcon>
            Flights
          </ToggleButtonCompact>
        </ToggleButtonGroup>
      </FixedFooterAppBar>
    </>
  );
}
