import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import FlightIcon from "@mui/icons-material/Flight";
import HotelIcon from "@mui/icons-material/Hotel";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { ToggleButtonCompact, ToggleButtonIcon } from "../controls/ToggleButton.js";
import FixedFooterAppBar from "../FixedFooterAppBar.js";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LoyaltyOutlined from "@mui/icons-material/LoyaltyOutlined";
import PublicIcon from "@mui/icons-material/Public";
import DeleteTripButton from "./DeleteTripButton.js";

export default function TripDetailsFooter({ onDeleteClick, onEditClick, id, tab }) {
  return (
    <FixedFooterAppBar>
      <Button
        startIcon={<FlightIcon fontSize="small" />}
        color="secondary"
        variant="outlined"
        component={ReactRouterLink}
        to={`/trips/${id}/new/travel`}
      >
        Add Travel
      </Button>
      <Button
        startIcon={<HotelIcon fontSize="small" />}
        color="secondary"
        variant="outlined"
        component={ReactRouterLink}
        to={`/trips/${id}/new/hotel`}
      >
        Add Hotel
      </Button>
      <Button
        startIcon={<AutoAwesomeIcon fontSize="small" />}
        color="secondary"
        variant="outlined"
        component={ReactRouterLink}
        to={`/trips/${id}/new/service`}
      >
        Add Service
      </Button>
      <Divider orientation="vertical" variant="middle" flexItem />

      <ToggleButtonGroup exclusive value={tab}>
        <ToggleButtonCompact
          color="primary"
          size="small"
          value="bookings"
          component={ReactRouterLink}
          to={`/trips/${id}`}
        >
          <ToggleButtonIcon>
            <ConfirmationNumberIcon />
          </ToggleButtonIcon>
          Bookings
        </ToggleButtonCompact>
        <ToggleButtonCompact
          color="primary"
          size="small"
          value="loyalty"
          component={ReactRouterLink}
          to={`/trips/${id}/loyalty`}
        >
          <ToggleButtonIcon>
            <LoyaltyOutlined />
          </ToggleButtonIcon>
          Loyalty
        </ToggleButtonCompact>
        <ToggleButtonCompact
          color="primary"
          size="small"
          value="itinerary"
          component={ReactRouterLink}
          to={`/trips/${id}/itinerary`}
        >
          <ToggleButtonIcon>
            <ConnectingAirportsIcon />
          </ToggleButtonIcon>
          Itinerary
        </ToggleButtonCompact>
      </ToggleButtonGroup>

      <Divider orientation="vertical" variant="middle" flexItem />

      <ToggleButtonCompact
        selected={tab === "map"}
        color="primary"
        size="small"
        value="map"
        component={ReactRouterLink}
        to={`/trips/${id}/map`}
      >
        <ToggleButtonIcon>
          <PublicIcon />
        </ToggleButtonIcon>
        Map
      </ToggleButtonCompact>

      <Divider orientation="vertical" variant="middle" flexItem />

      <DeleteTripButton onClick={onDeleteClick} />

      <Button
        startIcon={<DriveFileRenameOutlineIcon fontSize="small" />}
        color="secondary"
        variant="outlined"
        onClick={onEditClick}
      >
        Edit
      </Button>
    </FixedFooterAppBar>
  );
}
