import bookingsOrderedByDate from "./bookingsOrderedByDate.js";
import groupBookingsByLoyaltyProgram from "./groupBookingsByLoyaltyProgram.js";

export default function TripDetailsMangerInitialState({ trip, bookings }) {
  return {
    trip,
    bookings,
    groupBookingsByLoyaltyProgram: groupBookingsByLoyaltyProgram({ bookings }),
    bookingsOrderedByDate: bookingsOrderedByDate({ bookings }),
    editModalIsOpen: false,
  };
}
