import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import { STAY } from "../../models/BookingConstants.js";
import GenericBookingsTable from "../GenericBookingsTable/GenericBookingsTable.js";

export default function BookingStayList() {
  const bookings = useLiveQuery(() => db.bookings.where("type").equals(STAY).reverse().sortBy("startDate"));

  return <GenericBookingsTable bookings={bookings} isLoading={bookings === undefined} showTrip isStay />;
}
