import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import { db } from "../../models/db.js";
import CarriersContext from "./CarriersContext.js";

export default function CarriersProvider({ children }) {
  const carriers = useLiveQuery(() => db.carriers.toCollection().sortBy("id"));
  const isLoaded = carriers !== undefined;
  return <CarriersContext.Provider value={{ carriers: carriers || [], isLoaded }}>{children}</CarriersContext.Provider>;
}
