import React from "react";
import SegmentEditorRow from "./SegmentEditorRow.js";

export default function BookingSegments({ state, setSegment }) {
  return (
    <>
      {state.segments.map((segment) => (
        <SegmentEditorRow
          segment={segment}
          key={segment.id}
          setSegment={setSegment}
          showLeadingCarrier={state.segments.length > 1}
          referenceDate={state.suggestedDepartureDate}
          endDateMaxValue={state.endDateMaxValue}
          bookingDateMinValue={state.bookingDateMinValue}
        />
      ))}
    </>
  );
}
