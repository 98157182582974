import React from "react";
import GenericLoyaltyProgramActivityColumn from "./GenericLoyaltyProgramActivityColumn.js";

export default function GenericLoyaltyProgramActivityBookingColumn({ booking }) {
  let loyaltyProgramActivity = null;

  if (booking && booking?.stays?.length === 1) {
    loyaltyProgramActivity = booking.stays[0].loyaltyProgramActivities?.[0];
  } else if (booking && booking?.segments?.length === 1) {
    loyaltyProgramActivity = booking.segments[0].loyaltyProgramActivities?.[0];
  } else if (booking && booking?.segments?.length) {
    loyaltyProgramActivity = booking.segments.find((item) => item.loyaltyProgramActivities?.[0])
      ?.loyaltyProgramActivities?.[0];
  }

  return <GenericLoyaltyProgramActivityColumn loyaltyProgramActivity={loyaltyProgramActivity} />;
}
