import { createContext } from "react";

const GlobeDataContext = createContext({
  flights: undefined,
  stations: undefined,
  isLoaded: false,
  uniqueRoutes: [],
});

export default GlobeDataContext;
