import Typography from "@mui/material/Typography";
import React from "react";
import useLoyaltyPrograms from "../../../contexts/LoyaltyPrograms/useLoyaltyPrograms.js";
import { AutoCompleteListItem } from "../AutocompleteList.js";
import CreatableAutocomplete from "../CreatableAutocomplete.js";

export default function LoyaltyProgramsAutocomplete({ value, onChange, label, required }) {
  const { loyaltyPrograms } = useLoyaltyPrograms();

  const renderOption = (props, option) => {
    return (
      <AutoCompleteListItem {...props} key={props.key}>
        <Typography variant="body2">{option.title}</Typography>
        {option.note && (
          <Typography variant="body2" color="text.disabled" paddingLeft={1} display="inline-block">
            {option.note}
          </Typography>
        )}
      </AutoCompleteListItem>
    );
  };

  return (
    <CreatableAutocomplete
      value={value}
      onChange={onChange}
      options={loyaltyPrograms}
      label={label || "Program"}
      clearOnBlur
      disableClearable
      sx={{ width: 300 }}
      required={required}
      loading={false}
      renderOption={renderOption}
    />
  );
}
