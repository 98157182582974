import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import Card from "@mui/material/Card";
import CarriersTable from "./CarriersTable.js";

export default function CarriersList() {
  const carriers = useLiveQuery(() => db.carriers.toCollection().sortBy("id"));

  if (!carriers) return null;

  return (
    <Card square>
      <CarriersTable carriers={carriers} />
    </Card>
  );
}
