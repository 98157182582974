import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import { db } from "../../models/db.js";
import LoyaltyProgramsContext from "./LoyaltyProgramsContext.js";

export default function LoyaltyProgramsProvider({ children }) {
  const loyaltyPrograms = useLiveQuery(() => db.loyaltyPrograms.toCollection().sortBy("id"));
  const isLoaded = loyaltyPrograms !== undefined;

  return (
    <LoyaltyProgramsContext.Provider value={{ loyaltyPrograms, isLoaded }}>{children}</LoyaltyProgramsContext.Provider>
  );
}
