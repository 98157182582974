import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import React from "react";
import { useIntl } from "react-intl";
import { BOOKED, CANCELLED, REFUNDED } from "../../models/BookingConstants.js";

export default function PricePaid({ booking }) {
  const { formatNumber } = useIntl();

  const { price, priceCurrency, isPaid, status } = booking;
  if (price === undefined) {
    return (
      <Typography variant="body2" color="warning.main" component="span" fontStyle="italic">
        Unknown
      </Typography>
    );
  }

  const text = formatNumber(booking.price, { style: "currency", currency: priceCurrency });

  if (isPaid === false && status === BOOKED) {
    return (
      <Tooltip TransitionComponent={Zoom} title="Unpaid" arrow placement="right" color="warning">
        <Typography variant="body2" color="warning.main" component="span" fontStyle="italic">
          {text}
        </Typography>
      </Tooltip>
    );
  } else if ((isPaid && status === REFUNDED) || (!isPaid && status === CANCELLED)) {
    return (
      <Typography
        variant="body2"
        component="span"
        sx={{
          textDecoration: "line-through",
        }}
      >
        {text}
      </Typography>
    );
  }
  return text;
}
