import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useIntl } from "react-intl";
import { Link as ReactRouterLink } from "react-router-dom";
import CopyToClipboardText from "../CopyToClipboardText.js";
import { BookingLink } from "../GenericBookingsTable/BookingTitleLink.js";
import TextWrapper from "../GenericBookingsTable/TextWrapper.js";
import TripLink from "../GenericBookingsTable/TripLink.js";
import GenericLoyaltyProgramActivityColumn from "../LoyaltyProgramActivityList/GenericLoyaltyProgramActivityColumn.js";
import LoyaltyProgramActivityNameLink from "../LoyaltyProgramActivityList/LoyaltyProgramActivityNameLink.js";
import LoyaltyProgramActivityStatusIcon from "../LoyaltyProgramActivityList/LoyaltyProgramActivityStatusIcon.js";

export default function GenericStaysRow({ stay, linkState }) {
  const { formatDate, formatNumber } = useIntl();

  const primaryLoyaltyProgram = stay.loyaltyProgramActivities?.[0];

  const roomName = [stay.roomName, stay.roomNumber].filter((item) => item !== undefined).join(" / ");

  return (
    <TableRow hover>
      <TableCell
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {formatDate(stay.checkInDate, {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
        })}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {formatDate(stay.checkOutDate, {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
        })}
      </TableCell>
      <TableCell align="right">{stay.nights || ""}</TableCell>

      <TableCell>
        <Link
          to={`/hotels/${stay.brand.id}`}
          component={ReactRouterLink}
          state={linkState}
          color="inherit"
          underline="hover"
        >
          {stay.brand.title}
        </Link>
      </TableCell>

      <TableCell>
        <BookingLink id={stay.booking.id} title={stay.location.title} state={linkState} />

        {/*<BookingDescriptionText booking={booking} />*/}
      </TableCell>

      <TableCell>
        <TextWrapper width="100px">
          <CopyToClipboardText
            value={stay.carrierReference || stay.booking?.bookingReference}
            sx={{
              maxWidth: "inherit",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inline-block",
            }}
          />
        </TextWrapper>
      </TableCell>

      <TableCell>{roomName}</TableCell>

      {/*<TableCell>*/}
      {/*  <TextWrapper width="100px">{stay.roomNumber || ""}</TextWrapper>*/}
      {/*</TableCell>*/}

      <TableCell>
        <TextWrapper>
          <TripLink trip={stay.trip} id={stay.trip.id} />
        </TextWrapper>
      </TableCell>
      <GenericLoyaltyProgramActivityColumn loyaltyProgramActivity={primaryLoyaltyProgram} />
    </TableRow>
  );
}
