import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import Stack from "@mui/material/Stack";
import CreateLoyaltyProgramCard from "./CreateLoyaltyProgramCard.js";

import LoyaltyProgramCard from "./LoyaltyProgramCard.js";

export default function LoyaltyProgramsList() {
  const loyaltyPrograms = useLiveQuery(() => db.loyaltyPrograms.toCollection().sortBy("id"));

  if (!loyaltyPrograms) return null;

  return (
    <Stack spacing={2}>
      {loyaltyPrograms.map((loyaltyProgram) => (
        <LoyaltyProgramCard key={loyaltyProgram.id} loyaltyProgram={loyaltyProgram} />
      ))}
      <CreateLoyaltyProgramCard />
    </Stack>
  );
}
