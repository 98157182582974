import { useIntl } from "react-intl";
import Typography from "@mui/material/Typography";
import React from "react";

export default function TripDurationText({ trip }) {
  const { formatDate, formatNumber } = useIntl();

  if (!trip.startDate || !trip.endDate) return null;

  const startDate = formatDate(trip.startDate, {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "short",
  });

  const endDate = formatDate(trip.endDate, {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "short",
  });

  return (
    <Typography component="div" variant="body2" color="textSecondary">
      {`${startDate} - ${endDate} (${formatNumber(trip.durationInDays || 0)} days)`}
    </Typography>
  );
}
