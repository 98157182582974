import { addDays, differenceInCalendarDays } from "date-fns";
import { BOOKED, STAY, TRAVEL } from "../../models/BookingConstants.js";

export default function bookingsOrderedByDate({ bookings }) {
  const result = bookings
    .map((booking) => {
      return [
        ...(booking.segments || []).map((item) => ({
          item,
          booking,
          type: TRAVEL,
        })),
        ...(booking.stays || []).map((item) => ({
          item,
          booking,
          type: STAY,
        })),
      ];
    })
    .flat()
    .map((item) => {
      // Don't show skipped or cancelled bookings in the itinerary
      if (item.item.isSkipped || item.booking.status !== BOOKED) return null;
      const relevantStartDate = item.item.departureDate || item.item.checkInDate;
      let relevantEndDate = item.item.checkOutDate || null;

      if (item.type === TRAVEL && item.item.departureDate) {
        relevantEndDate = item.item.isOvernight ? addDays(item.item.departureDate, 1) : item.item.departureDate;
      }

      if (item.type === STAY && !relevantEndDate) {
        relevantEndDate = relevantStartDate;
      }

      const startDate = relevantStartDate || item.booking.bookingDate;
      // const endDate = item.type === STAY ? relevantEndDate || item.booking.bookingDate : null;
      return { ...item, startDate, endDate: relevantEndDate };
    })
    .filter((item) => item !== null)
    .sort((a, b) => {
      // A negative value indicates that a should come before b.
      // A positive value indicates that a should come after b.
      // Zero or NaN indicates that a and b are considered equal.
      const dateComparison = a.startDate.getTime() - b.startDate.getTime();
      if (dateComparison !== 0) return dateComparison;

      return a.endDate?.getTime() || 0 - b.endDate?.getTime() || 0;
    });

  return result.map((item, index, items) => {
    if (index === 0) {
      return { ...item, nights: 0 };
    }

    const previousItem = items[index - 1];

    const previousDate = previousItem.endDate || previousItem.startDate;

    return {
      ...item,
      previousDate,
      nights: differenceInCalendarDays(item.startDate, previousDate),
    };
  });
}
