import TableCell from "@mui/material/TableCell";
import React from "react";
import { useIntl } from "react-intl";
import LoyaltyProgramActivityNameLink from "./LoyaltyProgramActivityNameLink.js";
import LoyaltyProgramActivityStatusIcon from "./LoyaltyProgramActivityStatusIcon.js";

function PointsDisplayText({ points, tierPoints }) {
  const { formatNumber } = useIntl();

  const pointsText = points !== undefined ? formatNumber(points) : "-";

  if (tierPoints === undefined) {
    return pointsText;
  }

  return `${pointsText} / ${formatNumber(tierPoints)}`;
}

export default function GenericLoyaltyProgramActivityColumn({ loyaltyProgramActivity }) {
  if (!loyaltyProgramActivity) {
    return <TableCell colSpan={3} />;
  }

  return (
    <>
      <TableCell>
        <LoyaltyProgramActivityNameLink loyaltyProgramActivity={loyaltyProgramActivity} />
      </TableCell>

      <TableCell align="right" padding="none">
        <PointsDisplayText points={loyaltyProgramActivity.points} tierPoints={loyaltyProgramActivity.tierPoints} />
      </TableCell>

      <TableCell padding="checkbox">
        <LoyaltyProgramActivityStatusIcon loyaltyProgramActivity={loyaltyProgramActivity} />
      </TableCell>
    </>
  );
}
