import Joi from "joi";

export const PaymentMethodValidator = Joi.object({
  id: Joi.string().required(),
}).label("Station");

/**
 * Represents a payment method
 */
export default class PaymentMethod {
  constructor({ id } = {}) {
    this.id = id;
  }

  /**
   * The method id, which is also the title
   * @type {String}
   */
  id;

  /**
   * Indicates if this Station and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = PaymentMethodValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
