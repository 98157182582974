import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React from "react";

export default function FeatureCard({ value, title, variant }) {
  const isOutline = variant === "outlined";

  return (
    <Card
      sx={
        isOutline
          ? {
              borderColor: "primary.main",
            }
          : {
              backgroundColor: "primary.main",
              color: "primary.contrastText",
            }
      }
      variant={variant}
    >
      <CardContent sx={{ "&:last-child": { paddingBottom: 2 } }}>
        <Typography component="div" variant="h5" fontWeight={600}>
          {value}
        </Typography>
        <Typography variant="subtitle1" component="div" fontWeight={600}>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}
