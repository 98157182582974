import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import React from "react";
import { useIntl } from "react-intl";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import { Link as ReactRouterLink } from "react-router-dom";
import TripNoteText from "../TripDetails/TrimNoteText.js";

const longDateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  weekday: "short",
};

export default function TripsList({ trips }) {
  const { formatDate, formatNumber } = useIntl();

  return (
    <>
      <Card>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel active direction="desc" disabled>
                    Start
                  </TableSortLabel>
                </TableCell>
                <TableCell>End</TableCell>
                <TableCell>Days</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Payment Due</TableCell>
                <TableCell>Total Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trips.map((trip) => (
                <TableRow key={trip.id} hover>
                  <TableCell>{trip.startDate ? formatDate(trip.startDate, longDateOptions) : ""}</TableCell>
                  <TableCell>{trip.endDate ? formatDate(trip.endDate, longDateOptions) : ""}</TableCell>
                  <TableCell>{formatNumber(trip.durationInDays || 0)}</TableCell>

                  <TableCell>
                    <Link to={`/trips/${trip.id}`} component={ReactRouterLink}>
                      {trip.title}
                    </Link>
                    <TripNoteText component="span" note={trip.note} />
                  </TableCell>
                  <TableCell>
                    {trip.totalPricePaidUnpaid > 0
                      ? formatNumber(trip.totalPricePaidUnpaid, { style: "currency", currency: "GBP" })
                      : "-"}
                  </TableCell>
                  <TableCell>{formatNumber(trip.totalPricePaid, { style: "currency", currency: "GBP" })}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}
