import { BOOKED } from "../../models/BookingConstants.js";

export default function activeFlightsOnly(bookings) {
  return bookings.reduce((acc, booking) => {
    if (booking.status !== BOOKED || !booking.segments) return acc;

    booking.segments.forEach((segment) => {
      if (segment.isSkipped) return;
      if (segment.isSecondaryPassenger) return;
      acc.push(segment);
    });

    return acc;
  }, []);
}
