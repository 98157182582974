export default function TripsListInitialState() {
  return {
    trips: [],
    totalNights: null,
    totalPrice: null,
    totalPricePaid: null,
    totalPricePaidForServices: null,
    totalPricePaidForStays: null,
    totalPricePaidForTravel: null,
  };
}
