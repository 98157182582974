import Joi from "joi";
import generateIdFromTitle from "../lib/generateIdFromTitle.js";
import { BUS, TRAIN, BOAT, CAR, FLIGHT, OTHER } from "./CarrierConstants.js";

export const CarrierValidator = Joi.object({
  title: Joi.string().required(),
  id: Joi.string().required(),
  type: Joi.string().optional().allow(BUS, TRAIN, BOAT, CAR, FLIGHT, OTHER),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  lifetimeSpend: Joi.number().min(0).precision(2).required(),
}).label("Carrier");

/**
 * Represents a Carrier, which is a transport company, like an airline.
 */
export default class Carrier {
  constructor({ id, title, _id, createdAt, updatedAt, type, lifetimeSpend } = {}) {
    const candidateId = id || _id;
    this.id = generateIdFromTitle(candidateId || title);
    this.title = title || undefined;
    this.createdAt = createdAt || undefined;
    this.updatedAt = updatedAt || undefined;
    this.type = type || FLIGHT;
    this.lifetimeSpend = lifetimeSpend || 0.0;
  }

  /**
   * The title.
   * @type {String}
   */
  title;

  /**
   * The type ENUM value.
   * @type {String|undefined}
   */
  type;

  /**
   * The creation date.
   * @type {Date}
   */
  createdAt;

  /**
   * The update date.
   * @type {Date}
   */
  updatedAt;

  /**
   * The ID
   * @type {String}
   */
  id;

  lifetimeSpend;

  /**
   * Indicates if this instance and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = CarrierValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
